import axios from 'axios';
import Swal from "sweetalert2";
import { i18n } from "./i18n";
import mixins from "@/mixins";

const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) ? localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) : null;

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
        'Access-Control-Expose-Headers': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Branch': mixins.currentCountry(),
        'Language': localStorage.getItem('language') ? localStorage.getItem('language') : 'English',
        'Currency': mixins.currentCurrency()
    }
});

HTTP.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 400 || error.response.status === 422) {
        Swal.fire({
            icon: 'error',
            title: error.response.data.message ? error.response.data.message : error.response.data,
            showConfirmButton: false,
            timer: 2500
        });
    }
    if (error.response.status === 404) {
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: i18n.t('notFound'),
            showConfirmButton: false,
            timer: 1000
        });
    }
    return Promise.reject(error)
})