import { i18n } from "@/plugins/i18n";

const currency = {
    namespaced: true,
    state: {
        currency: localStorage.getItem('currency')
    },
    mutations: {
        SET_CURRENCY(state, lang) {
            localStorage.setItem('currency', lang);
            state.currency = lang;
            i18n.locale = lang;
        }
    },
    actions: {
        CHANGE_CURRENCY({ commit }, locale) {
            commit('SET_CURRENCY', locale)
        },
    },
    getters: {
        currency: state => state.currency,
    }
}

export default currency;