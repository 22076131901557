<template>
  <div class="product-grid" style="height: 95%">
    <div class="product-image">
      <router-link
        to="#"
        tag="a"
        data-toggle="modal"
        data-target="#productModal"
        @click.native="$emit('openModal', id)"
      >
        <img
          class="pic-1"
          alt="pic1"
          :src="getImageUrl(product.attachment)"
          style="height: 100%"
          loading="lazy"
        />
      </router-link>
      <ul class="social">
        <li>
          <router-link
            to="#"
            tag="a"
            data-toggle="modal"
            data-target="#productModal"
            data-tip="Quick View"
            @click.native="$emit('openModal', id)"
          >
            <i class="fa fa-eye"></i>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'product-details', params: { id: id } }"
            tag="a"
            data-tip="Product Details"
          >
            <i class="fa fa-link"></i>
          </router-link>
        </li>
      </ul>
      <div class="sizes">
        <span
          v-for="availableSize in product.availableSizes"
          :key="availableSize"
          >{{ availableSize }}</span
        >
      </div>
      <div
        v-if="product.colorCount > 1"
        class="colors"
        :style="'--image-color: ' + imageColorStyle"
      >
        <span>{{ product.colorCount }} {{ $t('color') }}</span>
      </div>
      <div
        v-if="take3Pay2"
        class="take3Pay2 product-new-label"
        :style="'--image-take3pay2: ' + imageTake3Pay2Style"
      ></div>
      <div v-else class="product-new-label" style="border-radius: 9px">
        {{ $t('sale') }}
      </div>
    </div>
    <div class="product-content">
      <h3 class="title">
        <a href="#">{{ product.name }}</a>
      </h3>
      <h3 v-if="alertText" class="alert">{{ alertText }}</h3>
      <div class="price text-center">
        {{ product.price && product.price.toLocaleString() }} {{ printCurrency() }}
      </div>
    </div>
  </div>
</template>

<script>
import colorImage from "@/assets/color.svg";
import take3Pay2Image from "@/assets/take3pay2.png";

export default {
  name: "OneProductGrid",
  data() {
    return {
      id: 0,
      imageColorStyle: "url(" + colorImage + ") no-repeat",
      imageTake3Pay2Style: "url(" + take3Pay2Image + ") no-repeat",
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    productId: {
      type: Number,
      required: false,
    },
    alertText: {
      type: String,
      default: null,
      required: false,
    },
    take3Pay2: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.productId) {
      this.id = this.productId;
    } else {
      this.id = this.product.id;
    }
  },
};
</script>

<style scoped>
.product-grid {
  border: none;
}

.sizes {
  background-color: #ffffff;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.product-grid:hover .sizes {
  opacity: 1;
  top: 98%;
}

.sizes span {
  font-weight: bold;
  padding: 2px;
  margin: 2px;
  display: inline-block;
  border: 1px solid #000000;
}

.colors {
  background-color: #ffffff;
  margin: 0;
  list-style: none;
  opacity: 1;
  position: absolute;
  bottom: 15%;
  right: 0;
  z-index: 3;
  background: var(--image-color);
  color: #fff;
  min-width: 87px;
  text-align: center;
  line-height: normal;
  display: flex;
  align-items: center;
  background-size: 100%;
  height: 31px;
  padding-left: 10px;
  box-sizing: border-box;
  font-weight: 500;
}

.colors span {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.take3Pay2 {
  position: absolute;
  top: 6px;
  left: -6px;
  width: 80px;
  height: 80px;
  background: var(--image-take3pay2);
  background-repeat: no-repeat;
  background-size: cover;
}

.alert {
  width: 100%;
  color: #ffffff;
  padding: 5px;
  border-radius: 9px;
  animation: alert 1s infinite ease-in-out;
  background-color: #fe6d2c;
}

@keyframes alert {
  from {
    background-color: #fe6d2c;
  }
  to {
    background-color: #fdfdfd;
  }
}
</style>