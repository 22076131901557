<template>
  <div>
    <TopCarousel />
    <Slider />
    <Take3Pay2 />
    <Collections />
    <div class="container">
      <h1><strong>Tesett&uuml;r Modasında Yeni Moda Renkler</strong></h1>
      <p>
        <strong
          >G&uuml;zel bir elbise satın alırken &ouml;zellikle birbirinden
          değişik renklerin se&ccedil;imi olduk&ccedil;a
          g&uuml;&ccedil;t&uuml;r. &Ouml;zel bir davete katılım sağlarken ya da
          bir d&uuml;ğ&uuml;n veya nişana giderken, hatta dışarı &ccedil;ıkarken
          de kıyafet se&ccedil;imine &ouml;zen g&ouml;sterilir. Bu zamanlarda
          se&ccedil;eceğimiz&nbsp;tesett&uuml;r&nbsp;elbisenin g&uuml;zel bir
          g&ouml;r&uuml;n&uuml;m oluşturmasının &ouml;nemi olduk&ccedil;a
          b&uuml;y&uuml;kt&uuml;r. Bu g&ouml;r&uuml;n&uuml;m&uuml; sağlayan ise
          genel olarak elbisenin model ve renk kombinasyonudur. Sitemizde bu
          renkler eşliğinde yeni sezon modeller beğenilerinize sunulmaktadır.
          Herkesin kendi kişisel tercihlerine g&ouml;re satın alabileceği
          birbirinden değişik renk ve modelleri ile kaliteli tesett&uuml;r
          elbise &ccedil;eşitleri modaselvim kreasyonunda.
          T&uuml;rkiye'nin&nbsp;tesett&uuml;r giyim sitesi Modaselvim'de en
          uygun fiyatlar sizleri bekliyor.</strong
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <h2>
        <strong>&nbsp;Farklı Renkler İle Yeni Moda Tesett&uuml;r Elbise</strong>
      </h2>
      <p>
        <strong
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&Ouml;zellikle
          bu sene siyah renk ve bordo ile birlikte petrol mavisi renkleri de
          yine tesett&uuml;r elbise modellerini s&uuml;slemektedir. Harika bir
          g&ouml;r&uuml;n&uuml;m sunan ve &uuml;stelik t&uuml;m kadınların
          &uuml;zerinde olduk&ccedil;a şık bir izlenim sağlayan bu renkler
          eşliğinde tek tip şekilde site b&uuml;nyesinde bulabilirsiniz. Aynı
          zamanda pudra ve z&uuml;mr&uuml;t renkleri ile birlikte yine bulut
          mavisi rengi &uuml;zerine yapılmış farklı elbise &ccedil;eşitleri
          t&uuml;m anıların olduk&ccedil;a hoşuna gidecek t&uuml;rden bir
          g&ouml;r&uuml;n&uuml;me sahiptir. Yeni sezon i&ccedil;erisinde farklı
          elbise modelleri kapsamında &ouml;zellikle a&ccedil;ık renkler &ouml;n
          plana &ccedil;ıkmaktadır ve bu konuda gidilmek isteyen bir&ccedil;ok
          farklı noktaya katılım g&ouml;sterirken t&uuml;m hanımlar hem
          kendilerine ferah bir ruh haline sokabilir aynı zamanda da etrafına da
          hoş bir g&ouml;r&uuml;n&uuml;m verebilir.</strong
        >
      </p>
      <p>
        <strong
          >&nbsp;&nbsp;Birbirinden Harika Renkler Eşliğinde Tesett&uuml;r Elbise
          &Ccedil;eşitleri</strong
        >
      </p>
      <p>
        <strong
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yeni
          sezon tesett&uuml;r elbise modelleri i&ccedil;erisinde aynı zamanda
          mor rengi ile birlikte bu &ccedil;ok değişik rengin yerine getirildiği
          elbise &ccedil;eşitleri de yine olduk&ccedil;a b&uuml;y&uuml;k bir
          talep almaya devam ediyor. Daha g&uuml;zel bir g&ouml;r&uuml;n&uuml;me
          b&uuml;r&uuml;yen ve aynı zamanda birbirinden değişik
          se&ccedil;enekler altında tasarımları bulunan harika elbiseleri bu
          kalitelisi de b&uuml;nyesinde &ccedil;ok uygun fiyatlar altın satın
          alabilir ve uzun yıllar kaliteli kumaş yapısı i&ccedil;erisinde
          keyifle giyebilirsiniz. &Ouml;zellikle zengin bir se&ccedil;enek
          altındaki bir&ccedil;ok farklı elbiselerin yanı sıra abiye modelleri
          ile birlikte dış giyim &ccedil;eşitleri ne de yeni bu kaliteli site
          kapsamında bulabilir, b&uuml;t&ccedil;enizi aşmayan fiyatları altında
          satın alma olanağına kolay bir bi&ccedil;imde erişebilirsiniz.</strong
        >
      </p>
      <h2>
        <strong
          >2021 Tesett&uuml;r Modasında &Ouml;ne &Ccedil;ıkan Moda
          Ayrıntıları</strong
        >
      </h2>
      <p>
        <strong
          >2021 yılı tesett&uuml;r modasında altın yıl sayılabilecek bir
          &ouml;neme sahiptir. Tesett&uuml;r 2021 modasında g&ouml;ze
          &ccedil;arpan olduk&ccedil;a şık ancak bir o kadar da kullanışlı
          modeller g&ouml;ze &ccedil;arpar. Canlı renkler 2021 yılının en
          belirgin &ouml;nde gelen &ouml;zelliğidir. Ayrıca kol detayları fark
          edilir tarzda tasarlanmıştır. T&uuml;rkiye'de tesett&uuml;r modasına
          y&ouml;n veren firma olarak Modaselvim&nbsp;en şık tesett&uuml;r
          kıyafetlere en uygun fiyatlarla ulaşmanızı hedeflemiştir.</strong
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <h2>
        <strong
          >&nbsp;Kaliteli Modeller, Capcanlı Renkler, Birbirinden
          &Ouml;zg&uuml;n Kıyafetler...&nbsp;</strong
        >
      </h2>
      <p>
        <strong
          >&nbsp;Tesett&uuml;r sekt&ouml;r&uuml; eskiye oranla olduk&ccedil;a
          canlanmış ve &ouml;nceki yıllarda eşit seviyede ilerlerken diğer giyim
          se&ccedil;eneklerine g&ouml;re daha fazla t&uuml;ketilen ve daha fazla
          &uuml;retilen bir yapıya kavuşmuştur. Bu artış tesett&uuml;r sitesi
          Moda Selvim'in kalıpları yıkıp, giyimin bir l&uuml;ks olmadığı
          algısını getirmesi ile sağlanmıştır. Moda Selvim bir anlamda kaliteli
          tesett&uuml;r sitesi olarak m&uuml;şterilerince kodlanmıştır.
          B&ouml;ylece rakipleri ister istemez site kalitesinde &uuml;r&uuml;n
          &ccedil;ıkarmaya başlamış ve zamanla fiyatlarını da bu aşamaya gelmesi
          i&ccedil;in d&uuml;zenlemek zorunda kalmıştır.</strong
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong
          >&nbsp;Kaliteli tesett&uuml;r sitesi bu bağlamda olduk&ccedil;a
          sorumluluk sahibi bir duruş ile olması gerekeni yapmıştır.
          Tesett&uuml;r giyim de diğer giyim &uuml;r&uuml;nleri gibi
          değerlendirilmelidir. Bu nedenle sıradan tek d&uuml;ze &uuml;retilen
          &uuml;r&uuml;nler yerine rengarenk, sıradanlıktan uzak, kadınların
          giydiklerinde kendilerini muhteşem hissedecekleri &uuml;r&uuml;nler
          tasarlar. Tesett&uuml;r modası i&ccedil;erisinde bir akım başlatır ve
          akıma uymak durumunda kalan diğer firmalar Moda Selvim'e eşlik eder.
          &Ouml;zg&uuml;n tasarım en başarılı tesett&uuml;r sitesi i&ccedil;in
          vazge&ccedil;ilmez bir &ccedil;alışma prensibidir.</strong
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <h2>
        <strong
          >Tesett&uuml;r Elbiseler Şıkır Şıkır, Efil Efil, Desen
          Desen&nbsp;</strong
        >
      </h2>
      <p>
        <strong
          >&nbsp;Elbiselerde tek renk yoğun iken kendinden desenli kumaşların
          h&acirc;kim olduğu modeller de &ccedil;oğunluktadır. Genellikle sade
          bir boyun detayı yer alır. Bu ince bir sarkıt bağcık olabilir. Kollar
          genellikle kabarık yapıda olup, bilek kısmında daralan
          b&uuml;zg&uuml;lere sahiptir. Bel detaylarına &ouml;nem verilmiş olup,
          bu detay daha zarif g&ouml;sterecek bir ustalık ile hazırlanmıştır.
          Bel oyuntusunu &ouml;n plana &ccedil;ıkaran bu işlem sayesinde daha
          ince g&ouml;r&uuml;nen kadınlar kendilerini daha iyi hissedeler. Abiye
          elbiseler de t&uuml;ller, danteller, fırfırlar &ouml;n planda olup her
          detay &ouml;zenle birbiri i&ccedil;erisinde uyumlu olacak tarzda
          tasarlanmıştır.</strong
        >
      </p>
      <p>&nbsp;</p>
    </div>
    <OurFeatures />
  </div>
</template>

<script>
import Slider from "@/components/partials/Slider";
import OurFeatures from "@/components/OurFeatures";
import Take3Pay2 from "@/components/Take3Pay2.vue";
import mixin from "@/mixins";
import Collections from "@/components/Collections.vue";
import TopCarousel from "@/components/TopCarousel.vue";

export default {
  name: "Home",
  components: {
    Slider,
    OurFeatures,
    Take3Pay2,
    Collections,
    TopCarousel
  },
  data() {
    return {
      productId: null,
      isLoading: true,
      image: mixin.defaultProductImage(),
    };
  },
};
</script>

<style scoped>
.homeCollections {
  text-align: center;
}

.homeCollections li {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 0 15px;
}

.homeCollections li img {
  width: 100%;
  height: 100%;
}

.homeCollections li span {
  font-size: 12px;
}
</style>