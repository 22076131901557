import { HTTP } from "@/plugins/axios";
import ProductType from "@/enums/ProductType";

const colors = {
    namespaced: true,
    state: {
        colors: {},
    },
    mutations: {
        SET_COLORS(state, value) { state.colors = value },
    },
    actions: {
        GET_ALL_COLORS({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/Get?start=0&end=10&objectName=${ProductType.AvailableColors}`)
                    .then((response) => {
                        commit('SET_COLORS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_COLORS', {})
                        reject(error)
                    });
            })
        },
    },
    getters: {
        colors: state => state.colors,
    }
}

export default colors;