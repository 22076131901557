<template>
  <div class="container mb--40">
    <carousel
      v-if="isLoading"
      :autoplay="true"
      :paginationEnabled="true"
      :perPageCustom="[
        [300, 3],
        [450, 3],
        [768, 8],
        [1024, 12],
      ]"
      :navigationEnabled="false"
      navigationNextLabel="<i class='fa fa-chevron-right'></i>"
      navigationPrevLabel="<i class='fa fa-chevron-left'></i>"
    >
      <slide
        v-for="topCollection in 8"
        :key="topCollection"
        :index="topCollection"
        style="margin-right: 4rem"
      >
        <img
          :src="getImageUrl()"
          :alt="topCollection"
          style="border-radius: 50%"
        />
      </slide>
    </carousel>
    <carousel
      v-else
      :autoplay="true"
      :paginationEnabled="true"
      :perPageCustom="[
        [300, 3],
        [450, 3],
        [768, 8],
        [1024, 12],
      ]"
      :navigationEnabled="false"
      navigationNextLabel="<i class='fa fa-chevron-right'></i>"
      navigationPrevLabel="<i class='fa fa-chevron-left'></i>"
    >
      <slide
        v-for="topCollection in topCollections"
        :key="topCollection.id"
        :index="topCollection.id"
        style="margin-right: 4rem"
      >
        <router-link
          :to="{ name: 'shop', query: { collection: topCollection.id } }"
          tag="a"
          :data-tip="topCollection.name"
        >
          <img
            :src="getImageUrl(topCollection.attachment)"
            :alt="topCollection.name"
          />
        </router-link>
      </slide>
    </carousel>
  </div>
</template>

<script>
export default {
  name: "TopCarousel",
  data() {
    return {
      isLoading: false,
      topCollections: [],
    }
  },
  mounted() {
    this.getCollections();
  },
  methods: {
    getCollections() {
      this.isLoading = true;
      this.$store
        .dispatch("collections/GET_TOP_COLLECTIONS")
        .then((response) => {
          this.topCollections = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>