<template>
  <div class="main-content-wrapper">
    <div class="contact-area ptb--80">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3 col-md-12 store-info">
            <div class="mb--30">
              <h1>Contact Us</h1>
              <p class="contact-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum voluptate provident vitae, voluptates magni saepe laborum cupiditate unde ab, cumque eveniet nesciunt laboriosam quis</p>
              <div class="contact-left-item">
                <i class="fa fa-phone"></i>
                <div class="contact-left-list">
                  <p>Phone: <br> <a href="#">+88 669 658 6586</a></p>
                </div>
              </div>
              <div class="contact-left-item">
                <i class="fa fa-envelope"></i>
                <div class="contact-left-list">
                  <p>Email: <br> <a href="mailto:demo@themeglass.com">demo@themeglass.com</a></p>
                </div>
              </div>
              <div class="contact-left-item pb--0">
                <i class="fa fa-map-marker"></i>
                <div class="contact-left-list">
                  <p class="mb--0">Our Location: <br> <a>Abc Road, New York</a></p>
                </div>
              </div>
            </div>
            <h1>Get in touch</h1>
            <form class="form form--contact" id="contact-form" action="#" method="post">
              <div class="sadia-form-group row">
                <div class="col-lg-12 col-12">
                  <input type="text" name="contact_name" id="contact_name" class="sadia-input-form" placeholder="Name">
                </div>
              </div>
              <div class="sadia-form-group row">
                <div class="col-lg-12 col-12">
                  <input type="email" name="contact_email" id="contact_email" class="sadia-input-form" placeholder="Email Address">
                </div>
              </div>
              <div class="sadia-form-group row">
                <div class="col-lg-12 col-12">
                  <input type="text" name="contact_subject" id="contact_subject" class="sadia-input-form" placeholder="Subject">
                </div>
              </div>
              <div class="sadia-form-group row">
                <div class="col-lg-12">
                  <textarea id="contact_message" name="contact_message" class="sadia-input-form sadia-input-form--textarea" placeholder="Message"></textarea>
                </div>
              </div>
              <div class="sadia-form-group text-left mb--0">
                <button type="submit" class="btn btn--small btn-style-3">Send Message</button>
              </div>

              <div class="form__output">
                <p class="form__messege"></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs"
}
</script>