<template>
  <div class="main-content-wrapper grey-color">
    <div class="error-wrapper pt--40 pb--80">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="error-text">
              <h1>404</h1>
              <h2>{{ $t('oops_this_page_is_not_available') }}</h2>
              <p>{{ $t('sorry_but_the_page_you_are_looking_for_is_not_found_please_make_sure_you_have_typed_the_current_url_correctly') }}</p>
              <router-link :to="{name: 'home'}">{{ $t('back_to_home') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>