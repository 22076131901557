import {HTTP} from "@/plugins/axios";

const settings = {
    namespaced: true,
    state: {
        settings: {},
    },
    mutations: {
        SET_SETTINGS (state, value) { state.settings = value },
    },
    actions: {
        GET_ALL_SETTINGS({commit}) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetPreferences`)
                    .then((response) => {
                        commit('SET_SETTINGS', JSON.parse(response.data[0].info1));
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_SETTINGS', {})
                        reject(error)
                    });
            })
        },
    },
    getters : {
        settings: state => state.settings,
    }
}

export default settings;