<template>
  <div class="modal fade" id="productModal" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 90%;margin: 10px auto 25px">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading" class="row">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <div class="col-md-3 col-sm-12 d-none d-sm-block">
                  <v-hopper
                      :vertical="true"
                      style="height: 100%"
                      :itemsToShow="4"
                      :infiniteScroll="true"
                      :autoPlay="true"
                      :playSpeed="2000"
                  >
                    <v-slide v-for="index in 4" :key="index" :index="index" style="margin-bottom: 15px !important">
                      <img :src="image" alt="product thumb">
                    </v-slide>
                    <hooper-navigation slot="hooper-addons" mode="fraction"></hooper-navigation>
                    <hooper-progress slot="hooper-addons"></hooper-progress>
                  </v-hopper>
                </div>
                <div class="col-md-9 col-sm-12">
                  <div class="tab-content product-thumb-large">
                    <div class="tab-pane active show fade">
                      <img :src="image" alt="Product Name">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <h3 class="product-title"><skeleton-loader width="100%" /></h3>
              <div class="product-price">
                <span class="sale-price"><skeleton-loader width="100%" /></span>
              </div>
              <p class="product-desc"><skeleton-loader width="100%" /></p>
              <div class="row">
                <div v-for="index in 4" :key="index" class="col-2" style="padding: 0">
                  <a id href="#" data-toggle="tab" class="nav-link">
                    <img class="img-thumbnail" :src="getImageUrl()" :alt="index" style="border-radius: 9px;box-shadow: 1px 1px 1px #ccc">
                  </a>
                </div>
              </div>
              <div class="product-varients">
                <div class="sadia-product-size pb--20 mt--20">
                  <span class="sadia-product-label"><b>{{ $t('sizes') }}:</b></span>
                  <span class="ml--10"><skeleton-loader /></span>
                </div>
                <div class="sadia-product-size pb--20 mt--20">
                  <span class="sadia-product-label"><b>{{ $t('tags') }}:</b></span>
                  <span class="ml--10"><skeleton-loader /></span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-md-6 col-sm-12">
              <div class="row">
                <div class="col-md-3 col-sm-12 d-none d-sm-block">
<!--                  <v-hopper-->
<!--                      :vertical="true"-->
<!--                      style="height: 100%"-->
<!--                      :itemsToShow="colorImages.length"-->
<!--                      :playSpeed="2000"-->
<!--                  >-->
                    <div v-for="colorImage in colorImages" :key="colorImage.id" style="margin-bottom: 20px !important">
                      <img id="product-image-0" :src="getImageUrl(colorImage.attachment)" alt="product thumb" @click="changeMainImage(colorImage.attachment)">
                    </div>
<!--                    <hooper-navigation slot="hooper-addons" mode="fraction"></hooper-navigation>-->
<!--                    <hooper-progress slot="hooper-addons"></hooper-progress>-->
<!--                  </v-hopper>-->
                </div>
                <div class="col-md-9 col-sm-12">
                  <div class="tab-content product-thumb-large">
                    <div class="tab-pane active show fade">
                      <img id="prev-image" :src="imageUrl" :alt="product.name">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <h3 class="product-title">
                {{ product.name }} - <code>{{ product.code }}</code>
              </h3>
              <div class="product-price">
                <del v-if="product.discount > 0" style="font-size: 18px">{{ (parseFloat(product.discount) + parseFloat(product.price)).toLocaleString() }}</del>
                <span v-if="product.discount > 0">&nbsp;</span>
                <span class="sale-price">{{ product.price.toLocaleString() }} {{ printCurrency() }}</span>
              </div>
              <p v-html="product.description" class="product-desc">{{ product.description }}</p>
              <p>
                <b>{{ $t('share') }}:</b> &nbsp;
                <a :href="facebookPageUrl" target="_blank" class="btn btn--small mb--10" style="padding: 5px 8px;margin-right: 10px">
                  <i class="fa fa-facebook"></i>
                  {{ $t('order_it') }}
                </a>
                <button class="btn btn--small mb--10" style="padding: 5px 8px" @click="copyProductURL(product.id)"><i class="fa fa-share"></i></button>
              </p>
              <div class="row">
                <div v-for="availableColor in product.availableColors" :key="availableColor.id" class="col-2" style="padding: 0">
                  <a id href="#" data-toggle="tab" class="nav-link" @click="changeColorImage(availableColor)">
                    <img class="img-thumbnail" :src="getImageUrl(availableColor.attachment)" :alt="availableColor.name" style="border-radius: 9px;box-shadow: 1px 1px 1px #ccc">
                  </a>
                </div>
              </div>
              <div class="product-varients">
                <div class="sadia-product-size pb--20 mt--20">
                  <span class="sadia-product-label"><b>{{ $t('sizes') }}:</b></span>
                  <span v-for="size in  product.availableDiskSizes" :key="size.id" class="ml--10 size">{{ size.name }}</span>
                </div>
                <!-- <div class="sadia-product-size pb--20 mt--20">
                  <span class="sadia-product-label"><b>Tags:</b></span>
                  <span v-for="tag in  product.tags" :key="tag.id" class="ml--10 tag">{{ tag.name }}</span>
                </div> -->
              </div>
              <table v-if="product.availableDiskSizes.length > 0" class="table table-bordered">
                <thead>
                  <tr class="text-center text-white" style="background-color: #df508e">
                    <th colspan="6">{{ $t('sizes_cm') }}</th>
                  </tr>
                  <tr class="text-center" style="background-color: #cccccc">
                    <th class="text-white" style="background-color: #da9695">{{ $t('size') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature1">{{ $t('chest') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature2">{{ $t('waist') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature3">{{ $t('hips') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature4">{{ $t('thigh') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature5">{{ $t('length') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center" v-for="availableDiskSize in product.availableDiskSizes" :key="availableDiskSize.id">
                    <td class="text-white font-weight-bold" style="background-color: #da9695">{{ availableDiskSize.name }}</td>
                    <td v-if="availableDiskSize.feature1">{{ availableDiskSize.feature1 }}</td>
                    <td v-if="availableDiskSize.feature2">{{ availableDiskSize.feature2 }}</td>
                    <td v-if="availableDiskSize.feature3">{{ availableDiskSize.feature3 }}</td>
                    <td v-if="availableDiskSize.feature4">{{ availableDiskSize.feature4 }}</td>
                    <td v-if="availableDiskSize.feature5">{{ availableDiskSize.feature5 }}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table v-if="product.availableRamSizes.length > 0" class="table table-bordered">
                <thead>
                  <tr class="text-center text-white" style="background-color: #df508e">
                    <th colspan="6">{{ $t('second_sizes_cm') }}</th>
                  </tr>
                  <tr class="text-center" style="background-color: #cccccc">
                    <th class="text-white" style="background-color: #da9695">{{ $t('size') }}</th>
                    <th v-if="product.availableRamSizes[0].feature1">{{ $t('chest') }}</th>
                    <th v-if="product.availableRamSizes[0].feature2">{{ $t('waist') }}</th>
                    <th v-if="product.availableRamSizes[0].feature3">{{ $t('hips') }}</th>
                    <th v-if="product.availableRamSizes[0].feature4">{{ $t('thigh') }}</th>
                    <th v-if="product.availableRamSizes[0].feature5">{{ $t('length') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center" v-for="availableRamSize in product.availableRamSizes" :key="availableRamSize.id">
                    <td class="text-white font-weight-bold" style="background-color: #da9695">{{ availableRamSize.name }}</td>
                    <td v-if="availableRamSize.feature1">{{ availableRamSize.feature1 }}</td>
                    <td v-if="availableRamSize.feature2">{{ availableRamSize.feature2 }}</td>
                    <td v-if="availableRamSize.feature3">{{ availableRamSize.feature3 }}</td>
                    <td v-if="availableRamSize.feature4">{{ availableRamSize.feature4 }}</td>
                    <td v-if="availableRamSize.feature5">{{ availableRamSize.feature5 }}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <table v-if="product.productFeatures.length > 0" class="table table-bordered">
                <thead>
                  <tr class="text-center text-white" style="background-color: #df508e">
                    <th colspan="6">{{ $t('third_sizes_cm') }}</th>
                  </tr>
                  <tr class="text-center" style="background-color: #cccccc">
                    <th class="text-white" style="background-color: #da9695">{{ $t('size') }}</th>
                    <th v-if="product.productFeatures[0].feature1">{{ $t('chest') }}</th>
                    <th v-if="product.productFeatures[0].feature2">{{ $t('waist') }}</th>
                    <th v-if="product.productFeatures[0].feature3">{{ $t('hips') }}</th>
                    <th v-if="product.productFeatures[0].feature4">{{ $t('thigh') }}</th>
                    <th v-if="product.productFeatures[0].feature5">{{ $t('length') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center" v-for="productFeature in product.productFeatures" :key="productFeature.id">
                    <td class="text-white font-weight-bold" style="background-color: #da9695">{{ productFeature.name }}</td>
                    <td v-if="productFeature.feature1">{{ productFeature.feature1 }}</td>
                    <td v-if="productFeature.feature2">{{ productFeature.feature2 }}</td>
                    <td v-if="productFeature.feature3">{{ productFeature.feature3 }}</td>
                    <td v-if="productFeature.feature4">{{ productFeature.feature4 }}</td>
                    <td v-if="productFeature.feature5">{{ productFeature.feature5 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {notify} from "@/plugins/constants";
import mixin from "@/mixins";
import {Navigation as HooperNavigation, Progress as HooperProgress} from "hooper";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "ProductModal",
  components: {SkeletonLoader, HooperNavigation, HooperProgress},
  props: {
    id: {
      required: true
    },
    changeUrl: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      isLoading: true,
      product: null,
      colorImages: [],
      imageUrl: null,
      image: mixin.defaultProductImage(),
      refreshAvailableColors: 0,
      facebookPageUrl: null,
    }
  },
  mounted() {
    if (this.$route.query.code && this.changeUrl) {
      this.loadProduct(this.$route.query.code);
      window.$(function() {
        window.$('#productModal').modal('show');
      });
      let vue = this;
      window.$('#productModal').on('hidden.bs.modal', function () {
        vue.removeQueryString();
      });
    }
    let country = localStorage.getItem('country');
    this.facebookPageUrl = country === 'Iraq'
        ? this.$store.getters['settings/settings'].iraqFacebookPage
        : country === 'Europe'
            ? this.$store.getters['settings/settings'].euroFacebookPage
            : this.$store.getters['settings/settings'].arabFacebookPage;
    },
  watch: {
    id: function (id) {
      this.loadProduct(id);
    }
  },
  methods: {
    removeQueryString() {
      this.$router.push({name: 'shop'});
    },
    async loadProduct(productId) {
      this.isLoading = true;
      await this.$store.dispatch('products/GET_ONE_PRODUCT', productId).then((response) => {
        this.product      = response.data;
        this.imageUrl     = mixin.getImageUrl(response.data.attachment);
        this.colorImages  = response.data.availableColors[0].attachments;
        this.refreshAvailableColors++;
        if (!this.$route.query.code && this.changeUrl) {
          this.$router.push({name: 'shop', query: {code: productId}});
        }
      }).catch((error) => {
        notify(error.response.data)
      }).finally(() => {
        this.isLoading = false;
      });
    },
    changeColorImage(color) {
      console.log(color);
      this.imageUrl     = mixin.getImageUrl(color.attachment);
      this.colorImages  = color.attachments;
    },
    changeMainImage(imageUrl) {
      this.imageUrl = mixin.getImageUrl(imageUrl);
    }
  }
}
</script>

<style scoped>
.size {
  padding: 7px;
  border: 1px solid;
  font-weight: bold;
}

.tag {
  background-color: #000000;
  color: #ffffff;
  padding: 7px;
  border: 1px solid;
  font-weight: bold;
}

.carousel-control-prev {
  position: relative;
   left: -110px;
   border-bottom: 0;
   font-size: 40px;
   color: #444;
 }

.carousel-control-next {
  position: relative;
  right: -110px;
  border-bottom: 0;
  font-size: 40px;
  color: #444;
}
</style>