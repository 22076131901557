<template>
  <div id="app">
    <div class="wrapper">
      <div id="content">
        <router-view />
        <CountryModal />
        <button id="countryModalBtn" type="button" class="btn btn-primary d-none" data-toggle="modal" data-target="#countryModal">
          Launch demo modal
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CountryModal from '@/components/CountryModal.vue';

export default {
  name: 'App',
  components: {CountryModal},
  mounted() {
    let selectedCountryCookie = localStorage.getItem('country');
    if (!selectedCountryCookie) {
      window.$('#countryModalBtn').click();
    }
    this.$store.dispatch('settings/GET_ALL_SETTINGS');
  },
}
</script>

<style>
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/nunito.ttf) format('truetype');
}

@font-face {
  font-family: 'Speda';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/speda.ttf) format('truetype');
}

* {
  font-family: Nunito, Speda, sans-serif;
}
.swal2-icon {
  color: #ffffff !important;
}

.swal2-popup {
  font-size: 15px !important;
}

.swal-wide {
  width:850px !important;
}
</style>