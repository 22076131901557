<template>
   <div class="dropdown">
    <button @click="dropDownClicked" class="dropbtn">
      <i class="fa fa-bars"></i>
    </button>
    <div id="myDropdown" class="dropdown-content">
      <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'home' }">{{ $t('home') }}</router-link>
      <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'shop' }">{{ $t('shop') }}</router-link>
      <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'contact-us' }">{{ $t('contact_us') }}</router-link>
      <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'about-us' }">{{ $t('about_us') }}</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DropDownMenu",
    mounted() {
      // Close the dropdown menu if the user clicks outside of it
      window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
          var dropdowns = document.getElementsByClassName("dropdown-content");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
            }
          }
        }
      }
    },
    methods: {
      dropDownClicked() {
        document.getElementById("myDropdown").classList.toggle("show")
      }
    },
  }
</script>

<style>
 /* Dropdown Button */
.dropbtn {
  background-color: #df508e;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #ff559e;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
</style>