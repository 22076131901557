import { i18n } from "@/plugins/i18n";

const language = {
    namespaced: true,
    state: {
        language: localStorage.getItem('language')
    },
    mutations: {
        SET_LANGUAGE(state, lang) {
            localStorage.setItem('language', lang);
            state.language = lang;
            i18n.locale = lang;
        }
    },
    actions: {
        CHANGE_LANGUAGE({ commit }, locale) {
            commit('SET_LANGUAGE', locale)
        },
    },
    getters: {
        language: state => state.language,
    }
}

export default language;