<template>
  <section class="tranding-product">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title text-center mb-50">
            <h2>{{ $t('take_3_pay_2') }}<i class="fa fa-shopping-cart"></i></h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="product-tab product-tab--2 content-grey-color">
            <div v-if="isLoading" class="row">
              <div v-for="index in 8" :key="index" class="col-lg-3 col-md-6 col-sm-12">
                <OneProductGridLoader />
              </div>
            </div>
            <div class="row">
              <div v-for="take3Pay in take3Pay2" :key="take3Pay.id" class="col-lg-3 col-md-6 col-sm-12">
                <OneProductGrid :product="take3Pay" :product-id="take3Pay.productId" @openModal="id => productModalId = id" :take3Pay2="true" :alert-text="'Take 3 Pay 2'"/>
              </div>
              <ProductModal :id="productModalId" :change-url="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {notify} from "@/plugins/constants";
import OneProductGrid from "@/components/OneProductGrid";
import OneProductGridLoader from "@/components/OneProductGridLoader";
import ProductModal from "@/components/ProductModal";

export default {
  name: "Take3Pay2",
  components: {OneProductGridLoader, OneProductGrid, ProductModal},
  data() {
    return {
      productModalId: null,
      isLoading: false,
      take3Pay2: []
    }
  },
  mounted() {
    this.loadFeaturedCollection();
  },
  methods: {
    loadFeaturedCollection() {
      this.isLoading = true;
      this.$store.dispatch('collections/GET_TAKE_3_PAY_2').then((response) => {
        this.take3Pay2 = response.data;
      }).catch((error) => {
        notify(error.response.data)
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>