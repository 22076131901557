import CryptoJS from "crypto-js";
import Logo from "@/assets/logo.png"
import ProductDefaultImage from "@/assets/default-product-image.png"
import ProductDefault from "@/assets/default-product.png"
import {
    notify
} from '@/plugins/constants';
import {i18n} from "@/plugins/i18n";

export default {
    encrypt(message) {
        return CryptoJS.AES.encrypt(message, process.env.VUE_APP_ENCRYPTION_KEY).toString();
    },
    decrypt(message) {
        return CryptoJS.AES.decrypt(message, process.env.VUE_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    },
    countries() {
        return [{
            name: "Iraq",
            kuName: "عیراق",
            arName: "العراق",
            flag: "https://flagcdn.com/iq.svg"
        },
            {
                name: "Jordan",
                kuName: "ئوردن",
                arName: "الأردن",
                flag: "https://flagcdn.com/jo.svg"
            },
            {
                name: "Morocco",
                kuName: "مەغریب",
                arName: "المغرب",
                flag: "https://flagcdn.com/ma.svg"
            },
            {
                name: "Palestine",
                kuName: "فەڵەستین",
                arName: "فلسطين",
                flag: "https://flagcdn.com/ps.svg"
            },
            {
                name: "Tunisia",
                kuName: "تونس",
                arName: "تونس",
                flag: "https://flagcdn.com/tn.svg"
            },
            {
                name: "Algeria",
                kuName: "جەزائیر",
                arName: "الجزائر",
                flag: "https://flagcdn.com/dz.svg"
            },
            {
                name: "Europe",
                kuName: "ئەوڕپا",
                arName: "أوروبا",
                flag: "https://flagcdn.com/eu.svg"
            },
        ]
    },
    projectLogo() {
        return Logo;
    },
    defaultProductImage() {
        return ProductDefaultImage;
    },
    currentCountry() {
        let countryLocal = this.getCookie('country')
            ? this.decrypt(this.getCookie('country'))
            : 'Iraq';

        let country = '';
        switch (countryLocal) {
            case 'Iraq': // 1
                country = 1;
                break;
            case 'Jordan': // Jordan
                country = 3;
                break;
            case 'Morocco': // 4
                country = 4;
                break;
            case 'Palestine': // 5
                country = 5;
                break;
            case 'Tunisia': // 6
                country = 6;
                break;
            case 'Algeria': // 7
                country = 7;
                break;
            case 'Europe': // 8
                country = 8;
                break;
            case 'Qatar': // 9
                country = 9;
                break;
            default:
                country = 'Iraq';
                break;
        }
        return country;
    },
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    currentCurrency() {
        return localStorage.getItem("currency")
            ? this.decrypt(localStorage.getItem("currency"))
            : 1;
    },
    printCurrency() {
        let localstorageCurrency = localStorage.getItem("currency")
            ? this.decrypt(localStorage.getItem("currency"))
            : 1;

        let currency = '';
        switch (localstorageCurrency) {
            case 1: // Iraq
            case 3: // Jordan
            case 6: // Tunisia
                currency = i18n.t('IQD');
                break;
            case 4: // Morocco
                currency = i18n.t('MAD');
                break;
            case 5: // Palestine
                currency = i18n.t('ILS');
                break;
            case 7: // Algeria
                currency = i18n.t('DZD');
                break;
            case 8: // Europe
                currency = i18n.t('USD');
                break;
            case 9: // Qatar
                currency = i18n.t('QAR');
                break;
            default:
                currency = i18n.t('USD');
                break;
        }
        return currency;
    },
    getImageUrl(url) {
        if (url) {
            if (url.startsWith('http://') || url.startsWith('https://')) {
                return url;
            } else {
                return process.env.VUE_APP_BASE_URL + url.replaceAll('\\', '/');
            }
        }
        return ProductDefault;
    },
    removeEmptyAndNull(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "" || obj[propName].length === 0) {
                delete obj[propName];
            }
        }
        return obj
    },
    copyProductURL(url = null) {
        let copyText = `${process.env.VUE_APP_WEB_BASE_URL}`;
        if (url) {
            copyText += `/product-details/${url}`;
        }
        navigator.clipboard.writeText(copyText);
        notify('Product Url Copied', 1000, 'bottom', 'success');
    }
}