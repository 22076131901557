export default {
    home: 'Home',
    shop: 'Shop',
    contact_us: 'Contact Us',
    about_us: 'About Us',
    all_rights_reserved: 'All rights reserved',
    language: 'Language',
    please_select_country: 'Please select country',
    countries: 'Countries',
    select_country: 'Select country',
    featured_collections: 'Featured Collections',
    shop_now: 'Shop Now',
    colors: 'Colors',
    sale: 'Sale',
    sizes: 'Sizes',
    tags: 'Tags',
    share: 'Share',
    order_it: 'Order it',
    sizes_cm: 'Sizes (cm)',
    size: 'Size',
    chest: 'Chest',
    waist: 'Waist',
    hips: 'Hips',
    thigh: 'Thigh',
    length: 'Length',
    second_sizes_cm: 'Second sizes (cm)',
    third_sizes_cm: 'Third sizes (cm)',
    take_3_pay_2: 'Take 3 pay 2',
    trending_products: 'Trending Products',
    product_details: 'Product Details',
    unauthorized: 'Unauthorized',
    oops_this_page_is_not_available: 'Oops! This page is not available',
    sorry_but_the_page_you_are_looking_for_is_not_found_please_make_sure_you_have_typed_the_current_url_correctly: 'Sorry but the page you are looking for is not found. Please make sure you have typed the current URL correctly.',
    back_to_home: 'Back to Home',
    internal_server_error: 'Internal Server Error',
    related_products: 'Related Products',
    apply_filters: 'Apply Filters',
    reset_filters: 'Reset Filters',
    filter_by_categories: 'Filter by Categories',
    filter_by_brand: 'Filter by Brand',
    filter_by_price: 'Filter by Price',
    no_results_found: 'No results found',
    sort_by: 'Sort by:',
    price_high_to_low: 'Price (high to low)',
    price_low_to_high: 'Price (low to high)',
    newest_first: 'Newest first',
    oldest_first: 'Oldest first',
    name_a_to_z: 'Name (A to Z)',
    name_z_to_a: 'Name (Z to A)',
    position: 'Position',
    search: 'Search:',
    type_something: 'Type something...',
    min_price: 'Min Price',
    max_price: 'Max Price',
}