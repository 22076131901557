<template>
  <div class="product-grid mb--30" style="height: 95%; border-radius: 20px">
    <div class="product-image" style="height: 100%">
      <a href="#" style="height: 100%">
        <img class="pic-1" alt="pic1" :src="image" style="height: 100%">
        <!--https://dummyimage.com/1024/cccccc/fff.png&text=ModaSelvim-->
      </a>
      <ul class="social">
        <li>
          <router-link
              to="#"
              tag="a"
              data-toggle="modal"
              data-target="#productModal"
              data-tip="Quick View"
          >
            <i class="fa fa-eye"></i>
          </router-link>
        </li>
        <li>
          <router-link to="#" tag="a" data-tip="Product Details">
            <i class="fa fa-link"></i>
          </router-link>
        </li>
      </ul>
      <span class="product-new-label">{{ $t('sale') }}</span>
    </div>
    <div class="product-content">
      <h3 class="title">
        <skeleton-loader width="95%" />
      </h3>
      <div class="price"><skeleton-loader width="95%" /></div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from "@/components/SkeletonLoader";
import mixin from "@/mixins";
export default {
  name: "OneProductGridLoader",
  components: {SkeletonLoader},
  data() {
    return {
      image: mixin.defaultProductImage()
    };
  }
}
</script>

<style scoped>
.placeholder {
  height: 100%;
}
</style>