import Swal from "sweetalert2";

export const notify = (title, timer = 1000, position = 'bottom', icon = 'error') => {
    Swal.fire({
        customClass: 'swal-wide',
        toast: true,
        icon: icon,
        position: position,
        title: title,
        showConfirmButton: false,
        timer: timer
    });
}