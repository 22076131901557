<template>
  <div
    class="modal fade"
    id="countryModal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="width: 40%; margin: 10px auto 25px"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('please_select_country') }}</h5>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img :src="logo" alt="Logo" width="256" height="256">
          </div>
          <div class="form-group">
            <label for="countries">{{ $t('countries') }}</label>
            <select
              id="countries"
              class="form-control form-control-lg selectpicker"
              @change="selectCountry"
            >
              <option value="Select Country">{{ $t('select_country') }}</option>
              <option v-for="country in allCountries" :key="country.name" :value="country.name">
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ogLogo from '@/assets/logo_og.png'
export default {
  name: "CountryModal",
  data() {
    return {
      allCountries: this.$helpers.countries(),
      logo: ogLogo
    };
  },
  methods: {
    selectCountry(e) {
      if (e.target.value === "Select Country") {
        return;
      }

      let currency = 1;
      switch (e.target.value) {
        case 'Iraq': // 1
        case 'Jordan': // 3
        case 'Tunisia': // 6
          currency = 2;
          break;
        case 'Morocco': // 4
          currency = 6;
          break;
        case 'Palestine': // 5
          currency = 3;
          break;
        case 'Algeria': // 7
          currency = 4;
          break;
        case 'Europe': // 8
          currency = 1;
          break;
        case 'Qatar': // 9
          currency = 5;
          break;
        default:
          currency = 1;
          break;
      }
      localStorage.setItem("currency", currency);
      localStorage.setItem("country", e.target.value);
      window.$("#countryModal").modal("hide");
    },
  },
};
</script>
