<template>
  <div class="placeholder wave">
    <div class="line" :style="{'height': height, 'width': width}"></div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoader',
  props: {
    height: {
      default: '15px'
    },
    width: {
      default: '80%'
    },
  },
}
</script>

<style scoped>
.placeholder {
  border-radius: 5px;
}
.placeholder.pulse div {
  animation: pulse 1s infinite ease-in-out;
  -webkit-animation: pulse 1s infinite ease-in-out;
}
.placeholder.wave div {
  animation: wave 1s infinite linear forwards;
  -webkit-animation: wave 1s infinite linear forwards;
  background: #df508e;
  background: linear-gradient(to right, #df508e 8%, #eb8eb6 18%, #df508e 33%);
  background-size: 800px 104px;
}
.placeholder div {
  background: #df508e;
}
.placeholder .square {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0 0 10px;
}
.placeholder .rectangle {
  float: left;
  width: 40px;
  height: 30px;
  margin: 0 0 10px;
}
.placeholder .line {
  height: 15px;
  width: 80%;
}
.placeholder .circle {
  float: left;
  width: 40px;
  height: 40px;
  margin: 0 15px 10px 0;
  border-radius: 40px;
}
@keyframes pulse {
  0% {
    background-color: #df508e;
  }
  50% {
    background-color: #df508e;
  }
  100% {
    background-color: #df508e;
  }
}
@-webkit-keyframes pulse {
  0% {
    background-color: #df508e;
  }
  50% {
    background-color: #df508e;
  }
  100% {
    background-color: #df508e;
  }
}
@keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes wave {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

</style>