import { HTTP } from "@/plugins/axios";
import GroupTypes from "@/enums/GroupTypes";
import ProductType from "@/enums/ProductType";

const collections = {
    namespaced: true,
    state: {
        topCollections: {},
        newCollections: {},
        take3Pay2: {},
        featuredCollections: {},
        trendingCollections: {},
    },
    mutations: {
        SET_TOP_COLLECTIONS(state, value) { state.newCollections = value },
        SET_NEW_COLLECTIONS(state, value) { state.newCollections = value },
        SET_TAKE_3_PAY_2(state, value) { state.newCollections = value },
        SET_FEATURED_COLLECTIONS(state, value) { state.featuredCollections = value },
        SET_TRENDING_COLLECTIONS(state, value) { state.trendingCollections = value },
    },
    actions: {
        GET_TOP_COLLECTIONS({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/Get?start=0&end=10&objectName=${ProductType.Collection}`)
                    .then((response) => {
                        commit('SET_TOP_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TOP_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        GET_NEW_COLLECTIONS({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetCurrentGroupProducts?start=0&end=3&GroupProductType=${GroupTypes.FrontPageSlider}`)
                    .then((response) => {
                        commit('SET_NEW_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_NEW_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        GET_TAKE_3_PAY_2({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetCurrentGroupProducts?start=0&end=8&GroupProductType=${GroupTypes.Take3Pay2}`)
                    .then((response) => {
                        commit('SET_TAKE_3_PAY_2', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TAKE_3_PAY_2', {})
                        reject(error)
                    });
            })
        },
        GET_FEATURED_COLLECTIONS({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetCurrentGroupProducts?start=0&end=8&GroupProductType=${GroupTypes.FeaturedCollection}`)
                    .then((response) => {
                        commit('SET_FEATURED_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_FEATURED_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
        GET_TRENDING_COLLECTIONS({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetCurrentGroupProducts?start=0&end=8&GroupProductType=${GroupTypes.FeaturedCollection}`)
                    .then((response) => {
                        commit('SET_TRENDING_COLLECTIONS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_TRENDING_COLLECTIONS', {})
                        reject(error)
                    });
            })
        },
    },
    getters: {
        topCollections: state => state.topCollections,
        newCollections: state => state.newCollections,
        take3Pay2: state => state.take3Pay2,
        featuredCollections: state => state.featuredCollections,
        trendingCollections: state => state.trendingCollections,
    }
}

export default collections;