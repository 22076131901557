<template>
<div class="banner-area">
  <div class="container">
    <div v-if="isLoading" class="row">
      <div class="col-12">
        <div class="banner-slide-wrapper">
          <div class="row">
            <div class="col-lg-12">
              <carousel
                  class="slider-wrapper"
                  :smartSpeed="600"
                  :autoplay="true"
                  :items="3"
                  :loop="true"
                  :nav="false"
                  :dots="false"
                  :autoplayTimeout="3000"
                  :responsive="{0:{items:1}}"
                  :navText="['<','>']"
              >
                <div v-for="index in 3" :key="index">
                  <div class="single-slider" :style="{ backgroundImage: 'url(' + image + ')' }">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-10 col-md-10">
                          <div class="slider-content slider-content--2">
                            <p class="heading-primary sadia-heading-primary" data-animation="fadeInDown" data-delay=".2s" data-duration="2s"> </p>
                            <router-link tag="a" to="#" class="btn slider-btn" data-animation="fadeInUp" data-delay=".7s" data-duration="2s">Purchase Now</router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <div class="banner-slide-wrapper">
          <div class="row">
            <div class="col-lg-12">
              <carousel
                  :key="refreshHomeCarousel"
                  id="homepage-slider"
                  class="slider-wrapper"
                  :smartSpeed="600"
                  :autoplay="true"
                  :items="3"
                  :loop="true"
                  :nav="false"
                  :dots="false"
                  :autoplayTimeout="3000"
                  :responsive="{0:{items:1}}"
                  :navText="['<','>']"
              >
                <div v-for="groupProduct in groupProducts" :key="groupProduct.id" style="cursor: pointer">
                  <div class="single-slider" :style="{ backgroundImage: 'url(' + getImageUrl(groupProduct.attachment) + ')' }" style="background-size: contain">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-10 col-md-10">
                          <div class="slider-content slider-content--2">
<!--                            <p class="heading-primary sadia-heading-primary" data-animation="fadeInDown" data-delay=".2s" data-duration="2s">{{ groupProduct.name }}</p>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import {notify} from "@/plugins/constants";
import mixin from "@/mixins";
import GroupTypes from "@/enums/GroupTypes";

export default {
  name: "Slider",
  components: { carousel },
  data() {
    return {
      isLoading: false,
      groupProducts: [],
      refreshHomeCarousel: 0,
      image: mixin.defaultProductImage(),
    }
  },
  mounted() {
    this.loadCollections();
  },
  methods: {
    loadCollections() {
      this.isLoading = true;
      this.$store.dispatch('products/GET_GROUP_PRODUCT', GroupTypes.Ads).then((response) => {
        this.groupProducts = response.data;
        this.refreshHomeCarousel++;
      }).catch((error) => {
        notify(error.response.data)
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>