<template>
  <section class="featured-product-area pb--50">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6" v-for="collection in collections" :key="collection.id">
          <div class="section-title text-left collection" @click="$router.push({name: 'shop', query: { type: collection.id }})">
            <img :src="getImageUrl(collection.attachment)" :alt="collection.text" style="object-fit: contain" />
            <p class="lead collection-name">
              {{ collection.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {notify} from "@/plugins/constants";

export default {
  name: "Collections",
  data() {
    return {
      isLoading: false,
      productModalId: null,
      collections: [],
    }
  },
  mounted() {
    this.loadCollections();
  },
  methods: {
    loadCollections() {
      this.isLoading = true;
      this.$store.dispatch('categories/GET_ALL_CATEGORIES').then((response) => {
        this.collections = response.data;
      }).catch((error) => {
        notify(error.response.data)
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>
.collection {
  cursor: pointer;
  box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.37);
  -webkit-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.37);
  -moz-box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.37);
}

.collection-name {
  font-size: 15px;
  background-color: black;
  color: aliceblue;
  padding: 2px 10px;
  font-weight: bold;
}
</style>