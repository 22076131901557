var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mb--40"},[(_vm.isLoading)?_c('carousel',{attrs:{"autoplay":true,"paginationEnabled":true,"perPageCustom":[
      [300, 3],
      [450, 3],
      [768, 8],
      [1024, 12] ],"navigationEnabled":false,"navigationNextLabel":"<i class='fa fa-chevron-right'></i>","navigationPrevLabel":"<i class='fa fa-chevron-left'></i>"}},_vm._l((8),function(topCollection){return _c('slide',{key:topCollection,staticStyle:{"margin-right":"4rem"},attrs:{"index":topCollection}},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.getImageUrl(),"alt":topCollection}})])}),1):_c('carousel',{attrs:{"autoplay":true,"paginationEnabled":true,"perPageCustom":[
      [300, 3],
      [450, 3],
      [768, 8],
      [1024, 12] ],"navigationEnabled":false,"navigationNextLabel":"<i class='fa fa-chevron-right'></i>","navigationPrevLabel":"<i class='fa fa-chevron-left'></i>"}},_vm._l((_vm.topCollections),function(topCollection){return _c('slide',{key:topCollection.id,staticStyle:{"margin-right":"4rem"},attrs:{"index":topCollection.id}},[_c('router-link',{attrs:{"to":{ name: 'shop', query: { collection: topCollection.id } },"tag":"a","data-tip":topCollection.name}},[_c('img',{attrs:{"src":_vm.getImageUrl(topCollection.attachment),"alt":topCollection.name}})])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }