<template>
  <!-- Main Content Wrapper Start -->
  <div class="main-content-wrapper">
    <div class="single-products-area ptb--80">
      <!-- Single Product Start -->
      <section class="single-product pb--40">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <!-- Tab Content Start -->
              <div class="tab-content product-thumb-large" id="myTabContent-3">
                <div class="tab-pane fade show active" id="product-large-one">
                  <div class="single-product-img">
                    <img
                      v-if="isLoading"
                      :src="image"
                      alt="Product Image"
                      loading="lazy"
                    />
                    <img
                      v-else
                      :src="imageUrl"
                      :alt="product.name"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <!-- Tab Content End -->

              <!-- Product Thumbnail Carousel Start -->
              <div class="product-thumbnail">
                <carousel
                  v-if="isLoading"
                  class="thumb-menu"
                  style="overflow: hidden"
                  :autoplay="false"
                  :items="3"
                  :loop="false"
                  :nav="false"
                  :dots="true"
                  :autoplayTimeout="5000"
                  :margin="20"
                  :responsive="{
                    0: { items: 1 },
                    300: { items: 2 },
                    400: { items: 2 },
                    768: { items: 2 },
                    992: { items: 3 },
                  }"
                  :navText="['<', '>']"
                  :padding="0"
                >
                  <div
                    class="thumb-menu-item"
                    style="height: 200px"
                    v-for="index in 3"
                    :key="index"
                  >
                    <a
                      id
                      href="#"
                      data-toggle="tab"
                      class="nav-link"
                      style="height: 100%"
                    >
                      <img
                        :src="image"
                        :alt="index"
                        height="100%"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </carousel>
                <carousel
                  v-else
                  :key="refreshImagesCarousel"
                  id="thumbmenu"
                  class="thumb-menu"
                  style="overflow: hidden"
                  :autoplay="false"
                  :items="3"
                  :loop="false"
                  :nav="false"
                  :dots="true"
                  :autoplayTimeout="5000"
                  :margin="20"
                  :responsive="{
                    0: { items: 1 },
                    300: { items: 2 },
                    400: { items: 2 },
                    768: { items: 2 },
                    992: { items: 3 },
                  }"
                  :navText="['<', '>']"
                  :padding="0"
                >
                  <div
                    class="thumb-menu-item"
                    style="height: 200px"
                    v-for="availableColor in product.availableColors"
                    :key="availableColor.id"
                  >
                    <a
                      id
                      href="#"
                      data-toggle="tab"
                      class="nav-link"
                      style="height: 100%"
                      @click="changeColorImage(availableColor)"
                    >
                      <img
                        :src="getImageUrl(availableColor.attachment)"
                        :alt="availableColor.name"
                        height="100%"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </carousel>
              </div>
              <!-- Product Thumbnail Carousel End -->
            </div>
            <div class="col-lg-7">
              <!-- Single Product Content Start -->
              <div v-if="isLoading" class="single-product-content">
                <p><skeleton-loader /></p>
                <h3 class="product-title"><skeleton-loader /></h3>
                <div class="product-price">
                  <span class="sale-price"><skeleton-loader /></span>
                </div>
                <hr />
                <div class="sadia-product-size pb--20 mt--20">
                  <p class="sadia-product-label"><b>Sizes:</b></p>
                  <span class="ml--10"><skeleton-loader /></span>
                </div>
                <div class="sadia-product-size pb--20 mt--20">
                  <p class="sadia-product-label"><b>Tags:</b></p>
                  <span class="ml--10"><skeleton-loader /></span>
                </div>
                <hr />
                <p class="product-desc mtb--20">
                  <skeleton-loader /><br />
                  <skeleton-loader /><br />
                  <skeleton-loader /><br />
                  <skeleton-loader /><br />
                  <skeleton-loader /><br />
                </p>
                <hr />
                <div class="social-share">
                  <span>{{ $t('share') }}:</span>
                  <ul class="social ml--10">
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-facebook social-icon-modal"></i
                      ></a>
                    </li>
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-twitter social-icon-modal"></i
                      ></a>
                    </li>
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-google-plus social-icon-modal"></i
                      ></a>
                    </li>
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-pinterest social-icon-modal"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else class="single-product-content">
                <p>{{ product.productTypeName }}</p>
                <h3 class="product-title">{{ product.name }}</h3>
                <div class="product-price">
                  <span class="sale-price"
                    >{{ parseInt(product.price).toLocaleString() }}
                    {{ printCurrency() }}
                  </span>
                  <del v-if="product.discount > 0" style="font-size: 18px">{{
                    (
                      parseFloat(product.discount) + parseFloat(product.price)
                    ).toLocaleString()
                  }}</del>
                </div>
                <hr />
                <div class="sadia-product-size pb--20 mt--20">
                  <span class="sadia-product-label"><b>{{ $t('sizes') }}:</b></span>
                  <span
                    v-for="size in product.availableDiskSizes"
                    :key="size.id"
                    class="ml--10 size"
                    >{{ size.name }}
                  </span>
                </div>
                <hr />
                <p class="product-desc mtb--20" v-html="product.description">
                  {{ product.description }}
                </p>
                <table v-if="product.availableDiskSizes.length > 0" class="table table-bordered">
                  <thead>
                  <tr class="text-center text-white" style="background-color: #df508e">
                    <th colspan="5">Sizes (CM)</th>
                  </tr>
                  <tr class="text-center" style="background-color: #cccccc">
                    <th class="text-white" style="background-color: #da9695">{{ $t('size') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature1">{{ $t('chest') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature2">{{ $t('waist') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature3">{{ $t('hips') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature4">{{ $t('thigh') }}</th>
                    <th v-if="product.availableDiskSizes[0].feature5">{{ $t('length') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center" v-for="availableDiskSize in product.availableDiskSizes" :key="availableDiskSize.id">
                    <td class="text-white font-weight-bold" style="background-color: #da9695">{{ availableDiskSize.name }}</td>
                    <td v-if="availableDiskSize.feature1">{{ availableDiskSize.feature1 }}</td>
                    <td v-if="availableDiskSize.feature2">{{ availableDiskSize.feature2 }}</td>
                    <td v-if="availableDiskSize.feature3">{{ availableDiskSize.feature3 }}</td>
                    <td v-if="availableDiskSize.feature4">{{ availableDiskSize.feature4 }}</td>
                    <td v-if="availableDiskSize.feature5">{{ availableDiskSize.feature5 }}</td>
                  </tr>
                  </tbody>
                </table>
                <br />
                <table v-if="product.availableRamSizes.length > 0" class="table table-bordered">
                  <thead>
                  <tr class="text-center text-white" style="background-color: #df508e">
                    <th colspan="5">{{ $t('second_sizes_cm') }}</th>
                  </tr>
                  <tr class="text-center" style="background-color: #cccccc">
                    <th class="text-white" style="background-color: #da9695">{{ $t('size') }}</th>
                    <th v-if="product.availableRamSizes[0].feature1">{{ $t('chest') }}</th>
                    <th v-if="product.availableRamSizes[0].feature2">{{ $t('waist') }}</th>
                    <th v-if="product.availableRamSizes[0].feature3">{{ $t('hips') }}</th>
                    <th v-if="product.availableRamSizes[0].feature4">{{ $t('thigh') }}</th>
                    <th v-if="product.availableRamSizes[0].feature5">{{ $t('length') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center" v-for="availableRamSize in product.availableRamSizes" :key="availableRamSize.id">
                    <td class="text-white font-weight-bold" style="background-color: #da9695">{{ availableRamSize.name }}</td>
                    <td v-if="availableRamSize.feature1">{{ availableRamSize.feature1 }}</td>
                    <td v-if="availableRamSize.feature2">{{ availableRamSize.feature2 }}</td>
                    <td v-if="availableRamSize.feature3">{{ availableRamSize.feature3 }}</td>
                    <td v-if="availableRamSize.feature4">{{ availableRamSize.feature4 }}</td>
                    <td v-if="availableRamSize.feature5">{{ availableRamSize.feature5 }}</td>
                  </tr>
                  </tbody>
                </table>
                <hr />
                <table v-if="product.productFeatures.length > 0" class="table table-bordered">
                  <thead>
                  <tr class="text-center text-white" style="background-color: #df508e">
                    <th colspan="5">{{ $t('third_sizes_cm') }}</th>
                  </tr>
                  <tr class="text-center" style="background-color: #cccccc">
                    <th class="text-white" style="background-color: #da9695">{{ $t('size') }}</th>
                    <th v-if="product.productFeatures[0].feature1">{{ $t('chest') }}</th>
                    <th v-if="product.productFeatures[0].feature2">{{ $t('waist') }}</th>
                    <th v-if="product.productFeatures[0].feature3">{{ $t('hips') }}</th>
                    <th v-if="product.productFeatures[0].feature4">{{ $t('thigh') }}</th>
                    <th v-if="product.productFeatures[0].feature5">{{ $t('length') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center" v-for="productFeature in product.productFeatures" :key="productFeature.id">
                    <td class="text-white font-weight-bold" style="background-color: #da9695">{{ productFeature.name }}</td>
                    <td v-if="productFeature.feature1">{{ productFeature.feature1 }}</td>
                    <td v-if="productFeature.feature2">{{ productFeature.feature2 }}</td>
                    <td v-if="productFeature.feature3">{{ productFeature.feature3 }}</td>
                    <td v-if="productFeature.feature4">{{ productFeature.feature4 }}</td>
                    <td v-if="productFeature.feature5">{{ productFeature.feature5 }}</td>
                  </tr>
                  </tbody>
                </table>
                <hr />
                <div class="social-share">
                  <span>{{ $t('share') }}:</span>
                  <ul class="social ml--10">
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-facebook social-icon-modal"></i
                      ></a>
                    </li>
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-twitter social-icon-modal"></i
                      ></a>
                    </li>
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-google-plus social-icon-modal"></i
                      ></a>
                    </li>
                    <li class="social-item">
                      <a href="#" class="sadia-social-link"
                        ><i class="fa fa-pinterest social-icon-modal"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Single Product Content End -->
            </div>
          </div>
        </div>
      </section>
      <!-- Single Product End -->

      <!-- Related Product Area Start -->
      <section class="related-product pt--80">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="text-center custom-title">
                <h2>{{ $t('related_products') }}</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="product-tab product-tab--2 content-grey-color">
                <div v-if="isLoading" class="row">
                  <div
                    v-for="index in 8"
                    :key="index"
                    class="col-lg-3 col-md-6 col-sm-12"
                  >
                    <OneProductGridLoader />
                  </div>
                </div>
                <div v-else class="row">
                  <div
                    v-for="relatedProduct in relatedProducts"
                    :key="relatedProduct.id"
                    class="col-lg-3 col-md-6 col-sm-12"
                  >
                    <OneProductGrid
                      :product="relatedProduct"
                      @openModal="openProductModal"
                    />
                  </div>
                  <ProductModal :id="productModalId" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Related Product Area End -->
    </div>
  </div>
  <!-- Main Content Wrapper End -->
</template>

<script>
import { notify } from "@/plugins/constants";
import carousel from "vue-owl-carousel";
import mixin from "@/mixins";
import OneProductGrid from "@/components/OneProductGrid";
import ProductModal from "@/components/ProductModal";
import OneProductGridLoader from "@/components/OneProductGridLoader";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "ProductDetails",
  components: {
    SkeletonLoader,
    OneProductGridLoader,
    ProductModal,
    OneProductGrid,
    carousel,
  },
  data() {
    return {
      isLoading: true,
      refreshImagesCarousel: 0,
      relatedProducts: [],
      productModalId: null,
      imageUrl: null,
      image: mixin.defaultProductImage(),
      product: {},
    };
  },
  mounted() {
    this.loadProduct(this.$route.params.id);
    this.loadRelatedProduct(this.$route.params.id);
  },
  methods: {
    async loadProduct(productId) {
      this.isLoading = true;
      await this.$store
        .dispatch("products/GET_ONE_PRODUCT", productId)
        .then((response) => {
          this.product = response.data;
          this.imageUrl = mixin.getImageUrl(response.data.attachment);
          this.refreshImagesCarousel++;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.push("/404");
          } else {
            this.$store.dispatch(notify, {
              type: "error",
              message: error.response.data.message,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async loadRelatedProduct(productId) {
      this.isLoading = true;
      await this.$store
        .dispatch("products/GET_RELATED_PRODUCTS", productId)
        .then((response) => {
          this.relatedProducts = response.data;
        })
        .catch((error) => {
          notify(error.response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeColorImage(color) {
      this.imageUrl = mixin.getImageUrl(color.attachment);
      this.colorImages = color.attachments;
    },
    openProductModal(productId) {
      this.productModalId = productId;
    },
  },
};
</script>

<style>
.size {
  padding: 7px;
  border: 1px solid;
  font-weight: bold;
}

.tag {
  background-color: #000000;
  color: #ffffff;
  padding: 7px;
  border: 1px solid;
  font-weight: bold;
}
</style>