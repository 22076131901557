import Vue from 'vue'
import VueRouter from 'vue-router'
import NotFound from "@/views/errors/404";
import Home from "@/views/Home";
import ProductDetails from "@/views/ProductDetails";
import Main from "@/views/Main";
import AboutUs from "@/views/AboutUs";
import ContactUs from "@/views/ContactUs";
import Shop from "@/views/Shop";
import {i18n} from "@/plugins/i18n";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        component: Main,
        children: [
            {
                path: '',
                name: 'home',
                component: Home,
                meta: {
                    title: i18n.t('home'),
                }
            },
            {
                path: '/shop',
                name: 'shop',
                component: Shop,
                meta: {
                    title: i18n.t('shop'),
                }
            },
            {
                path: '/product-details/:id',
                name: 'product-details',
                component: ProductDetails,
                meta: {
                    title: i18n.t('product_details'),
                }
            },
            {
                path: '/about-us',
                name: 'about-us',
                component: AboutUs,
                meta: {
                    title: i18n.t('about_us'),
                }
            },
            {
                path: '/contact-us',
                name: 'contact-us',
                component: ContactUs,
                meta: {
                    title: i18n.t('contact_us'),
                }
            },
            {path: "*", name: "notfound", component: NotFound}
        ]
    },
    {path: "*", name: "notfound2", component: NotFound}
]

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

const DEFAULT_TITLE = process.env.VUE_APP_PROJECT_NAME;
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router