<template>
  <div class="page-loader" v-if="!isLoading">
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isLoading: true
    }
  },
  mounted() {
    // document.onreadystatechange = () => {
    //   setTimeout(() => {
    //     if (document.readyState === "complete") {
    //       this.isLoading = true
    //     }
    //   }, 2000);
    // }
  },
}
</script>

<style scoped>
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #333;
  z-index: 999;
}
.cube {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.cube:nth-child(1) {
  background-color: #AC3D6D;
}
.cube:nth-child(2) {
  background-color: #D24A85;
}
.cube:nth-child(3) {
  background-color: #df508e;
}
.cube:nth-child(4) {
  background-color: #df508e;
}
.cube:first-child {
  animation: left 1s infinite;
}
.cube:last-child {
  animation: right 1s infinite 0.5s;
}
@keyframes left {
  40% {
    transform: translateX(-60px);
  }
  50% {
    transform: translateX(0);
  }
}
@keyframes right {
  40% {
    transform: translateX(60px);
  }
  50% {
    transform: translateX(0);
  }
}
</style>