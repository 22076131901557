export default Object.freeze(
    {
        'NoData': 'NoData',
        'AvailableDiskSizes': 'AvailableDiskSizes',
        'AvailableRamSizes': 'AvailableRamSizes',
        'AvailableColors': 'AvailableColors',
        'Brand': 'Brand',
        'ProductType': 'ProductType',
        'InstallmentMethod': 'InstallmentMethod',
        'Tag': 'Tag',
        'Collection': 'Collection'
    }
)