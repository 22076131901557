import { HTTP } from "@/plugins/axios";
import qs from "qs";
import mixin from "@/mixins";

const products = {
    namespaced: true,
    state: {
        getAllProduct: {},
        oneProduct: {},
        latestProducts: {},
        relatedProducts: {},
        groupProducts: {},
    },
    mutations: {
        SET_ALL_PRODUCT(state, value) { state.getAllProduct = value },
        SET_ONE_PRODUCT(state, value) { state.oneProduct = value },
        SET_LATEST_PRODUCTS(state, value) { state.latestProducts = value },
        SET_RELATED_PRODUCTS(state, value) { state.relatedProducts = value },
        SET_GROUP_PRODUCT(state, value) { state.groupProducts = value },
    },
    actions: {
        GET_ALL_PRODUCT({ commit }, serverParams) {
            return new Promise((resolve, reject) => {
                mixin.removeEmptyAndNull(serverParams);
                HTTP.get(`/ClientSide/GetProducts`, {
                        params: serverParams,
                        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
                    })
                    .then((response) => {
                        commit('SET_ALL_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ALL_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        GET_ONE_PRODUCT({ commit }, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetProductById?id=${id}`)
                    .then((response) => {
                        commit('SET_ONE_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_ONE_PRODUCT', {})
                        reject(error)
                    });
            })
        },
        GET_LATEST_PRODUCTS({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetProducts?start=0&end=6&sort=id&order=desc`)
                    .then((response) => {
                        commit('SET_LATEST_PRODUCTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LATEST_PRODUCTS', {})
                        reject(error)
                    });
            })
        },
        GET_RELATED_PRODUCTS({ commit }, id) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetRelatedProducts?id=${id}`)
                    .then((response) => {
                        commit('SET_RELATED_PRODUCTS', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_RELATED_PRODUCTS', {})
                        reject(error)
                    });
            })
        },
        GET_GROUP_PRODUCT({ commit }, group) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/ClientSide/GetGroup?GroupProductType=${group}`)
                    .then((response) => {
                        commit('SET_GROUP_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_GROUP_PRODUCT', {})
                        reject(error)
                    });
            })
        },
    },
    getters: {
        getAllProduct: state => state.getAllProduct,
        oneProduct: state => state.oneProduct,
        latestProducts: state => state.latestProducts,
        relatedProducts: state => state.relatedProducts,
        groupProducts: state => state.groupProducts,
    }
}

export default products;