<template>
  <!-- Header area Start -->
  <header class="header">
    <div class="header-top d-none d-md-block" style="background-color: #df508e">
      <div class="container">
        <div class="row no-gutters align-items-center">
          <div class="col-md-6 col-sm-12">
            <!-- Header Top Nav Start -->
            <div class="language-currancy d-flex justify-content-md-end justify-content-center">
              <div class="top-hdrop">
                <div class="dropdown sadia-top-dropdown">
                  <button class="btn btn--small dropdown-toggle" type="button" id="language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-language text-white"></i> &nbsp;
                    {{ $t('language')}}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="language">
                    <a class="dropdown-item" href="#" @click="changeLanguage('Kurdish')">
                      <img :src="kurdishFlag" class="img-fluid" width="16" height="16" :alt="$t('kurdish')">
                      کوردی
                    </a>
                    <a class="dropdown-item" href="#" @click="changeLanguage('Arabic')">
                      <img :src="arabicFlag" class="img-fluid" width="16" height="16" :alt="$t('arabic')">
                      عربی
                    </a>
                    <a class="dropdown-item" href="#" @click="changeLanguage('English')">
                      <img :src="englishFlag" class="img-fluid" width="16" height="16" :alt="$t('english')">
                      English
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="top-hdrop">
                <div class="dropdown sadia-top-dropdown">
                  <button class="btn btn--small dropdown-toggle" type="button" id="currency" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-money text-white"></i> &nbsp;
                    Currency
                  </button>
                  <div class="dropdown-menu" aria-labelledby="currency">
                    <a class="dropdown-item" href="#" @click="changeCurrency('Dinar')">Dinar</a>
                    <a class="dropdown-item" href="#" @click="changeCurrency('Dollar')">Dollar</a>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- Header Top Nav End -->
          </div>
        </div>
      </div>
    </div>
    <div class="header-middle fixed-header">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-2 col-md-4 col-sm-4 col-4 text-left" style="margin: auto 0">
            <router-link tag="a" class="logo-box" :to="{ name: 'home' }">
              <img :src="logo" alt="logo">
            </router-link>
          </div>
          <div class="col-lg-9 col-md-6 d-none d-lg-block">
            <div class="header-middle-right justify-content-md-end">
              <nav class="main-navigation">
                <!-- Mainmenu Start -->
                <ul class="mainmenu sadia-main-menu">
                  <li class="sadia-mainmenu-itm">
                    <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'home' }">{{ $t('home') }}</router-link>
                  </li>
                  <li class="sadia-mainmenu-itm">
                    <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'shop' }">{{ $t('shop') }}</router-link>
                  </li>
                  <li class="sadia-mainmenu-itm">
                    <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'contact-us' }">{{ $t('contact_us') }}</router-link>
                  </li>
                  <li class="sadia-mainmenu-itm">
                    <router-link tag="a" class="sadia-mainmenu-link" exact :to="{ name: 'about-us' }">{{ $t('about_us') }}</router-link>
                  </li>
                </ul>
                <!-- Mainmenu End -->
              </nav>
            </div>
          </div>
          <div class="col-md-6 col-sm-4 col-4 d-block d-lg-none text-center mt--10">
            <DropDownMenu/>
          </div>
          <div class="col-lg-1 col-md-2 col-sm-4 col-4">
            <div class="header-middle-right justify-content-md-end">
              <!-- Header search Start -->
              <div class="header-user header-area-user">
                <a class="sadia-user-toggle" data-vw-action="open-search-popup" @click="searchOpened">
                  <i class="fa fa-search header-heart-icon"></i>
                </a>
              </div>
              <div class="vw-search-popup">
                <div class="vw-search-popup-wrapper">
                  <div class="inner">
                    <form class="clearfix" @submit.prevent="search">
                      <input type="search" name="search" class="search-field" :placeholder="$t('type_something')" v-model="searchInput">
                      <button type="submit" class="search-submit">
                        <i class="fa fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
                <a href="#" class="vw-search-popup-close" data-vw-action="close-search-popup" @click="closeSearch">
                  <span></span>
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Header area End -->
</template>

<script>
import $ from 'jquery';
import kurdishFlag from '@/assets/flags/ku.svg';
import arabicFlag from '@/assets/flags/ar.svg';
import englishFlag from '@/assets/flags/en.svg';
import DropDownMenu from '@/components/partials/DropDownMenu.vue';

export default {
  name: "Header",
  data() {
    return {
      searchInput: null,
      kurdishFlag,
      arabicFlag,
      englishFlag,
      logo: this.$helpers.projectLogo()
    }
  },
  components: {DropDownMenu},
  created() {
    document.addEventListener('keyup', function (evt) {
      if (evt.key === 'Escape') {
        $('.vw-search-popup').first().fadeOut(600);
      }
    });
  },
  methods: {
    changeLanguage(language = 'English') {
      if (this.$store.getters['language/language'] !== language) {
        this.$store.dispatch('language/CHANGE_LANGUAGE', language).then(() => window.location.reload())
      }
    },
    changeCurrency(currency = 'Dinar') {
      if (this.$store.getters['currency/currency'] !== currency) {
        this.$store.dispatch('currency/CHANGE_CURRENCY', currency).then(() => window.location.reload())
      }
    },
    searchOpened(e) {
      e.preventDefault();
      $('.vw-search-popup').first().fadeIn(600, function () {
        $(this).find('input[type=search]').trigger('focus');
      });
    },
    closeSearch() {
      $('[data-vw-action=close-search-popup]').on('click', function (e) {
        e.preventDefault();
        $('.vw-search-popup').first().fadeOut(600);
      });
    },
    search() {
      if (this.searchInput.length >= 3) {
        this.$emit('searchInput', this.searchInput);
        $('.vw-search-popup').first().fadeOut(600);
        this.$router.push({name: 'shop', query: {search: this.searchInput}});
      }
    }
  }
}
</script>

<style scoped>
.logo-box img {
  padding: 15px 0;
}

.active {
  color: #daa7bd
}

.btn {
  color: #ffffff;
}

.btn--small {
  padding: 1rem
}

.sadia-top-dropdown .dropdown-toggle:not([href]):hover {
  color: #ffffff !important
}
</style>