import Vue from 'vue'
import VueI18n from 'vue-i18n';
import messages from '@/translations';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    lazy: true,
    silentTranslationWarn: process.env.NODE_ENV === 'development',
    locale: localStorage.getItem('language') ? localStorage.getItem('language') : 'Kurdish',
    fallbackLocale: ['Kurdish', 'Arabic', 'English'],
    messages,
});