<template>
  <div>
    <PageLoader />
    <Header @searchInput="searchInputMethod" />
    <router-view :searchInputText="searchInput" />
    <FooterTopArea />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/Header";
import Footer from "@/components/partials/Footer";
import FooterTopArea from "@/components/partials/FooterTopArea";
import PageLoader from "@/components/PageLoader";

export default {
  name: "Main",
  components: {PageLoader, FooterTopArea, Footer, Header},
  data() {
    return {
      searchInput: null,
      countryModal: false,
      selectedCountry: null,
    }
  },
  mounted() {
    // this.countryModal = true;
    // this.$cookies.set("ModaSelvimToken", this.$helpers.encrypt("No One Can Do It"), "30d");
    // this.$helpers.decrypt(this.$cookies.get("ModaSelvimToken"));
  },
  methods: {
    searchInputMethod(e) {
      this.searchInput = e
    }
  }
}
</script>

<style scoped>

</style>