import Vue from 'vue'
import Vuex from 'vuex'
import products from "@/store/modules/products";
import collections from "@/store/modules/collections";
import categories from "@/store/modules/categories";
import brands from "@/store/modules/brands";
import colors from "@/store/modules/colors";
import language from "@/store/modules/language";
import currency from "@/store/modules/currency";
import settings from "@/store/modules/settings";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: { categories, products, collections, brands, colors, language, currency, settings }
})