<template>
  <!-- Footer top area Start -->
  <div class="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row pt--80 pb--40 footer-widget-row">

          <!-- Footer Widget Start -->
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="footer-widget">
              <h3 class="widget-title">About Us</h3>
              <div class="f-logo menu-widget">
                <div class="mb--20">
                  <router-link tag="a" class="logo-box" :to="{ name: 'home' }">
                    <img :src="logo" alt="Logo">
                  </router-link>
                </div>
                <p>If you want to Get all the latest information on Events, Sales and Offers. Sign up for newsletter today. Be the first to know</p>

                <ul class="list-unstyled CTAs mt--20">
                  <li class="social-item"><a href="#" class="sadia-social-link"><i class="fa fa-facebook"></i></a></li>
                  <li class="social-item"><a href="#" class="sadia-social-link"><i class="fa fa-twitter"></i></a></li>
                  <li class="social-item"><a href="#" class="sadia-social-link"><i class="fa fa-youtube"></i></a></li>
                  <li class="social-item"><a href="#" class="sadia-social-link"><i class="fa fa-google-plus"></i></a></li>
                  <li class="social-item"><a href="#" class="sadia-social-link"><i class="fa fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Footer Widget End -->

          <!-- Footer Widget Start -->
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="footer-widget">
              <h3 class="widget-title">Contact Us</h3>
              <div class="menu-widget">
                <ul>
                  <li><a href="#"><i class="fa fa-map-marker"></i> #183 Scelerisque Road 10. Luxemburg. Alice</a></li>
                  <li><a href="#"><i class="fa fa-phone"></i> +001 2233 456 / +001 2233 929</a></li>
                  <li><a href="#"><i class="fa fa-envelope"></i> demo@yahoo.com / demo2@yahoo.com</a> </li>
                  <li><a href="#"><i class="fa fa-skype"></i> sadia-skype</a> </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Footer Widget End -->

          <!-- Footer Widget Start -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="footer-widget">
              <h3 class="widget-title">Subscribe</h3>
              <div class="f-logo">
                <p>Join our community and be the first to know about offers, new collections and interior trends.</p>
                <div class="subscribe mt--20">
                  <form action="#">
                    <input placeholder="Enter Your Mail" type="email">
                    <button value="Submit" type="submit" class="search-button">Go</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Footer Widget End -->

        </div>
      </div>
    </div>
  </div>
  <!-- Footer top area End -->
</template>

<script>
export default {
  name: "FooterTopArea",
  data () {
    return {
      logo: this.$helpers.projectLogo()
    }
  }
}
</script>