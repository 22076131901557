<template>
  <!-- Policy area Start -->
  <section class="policy-area grey-color">
    <div class="container">
      <div class="row pt--50 pb--20 policy-box">
        <!-- Method Box Start -->
        <div class="col-md-3 col-lg-3">
          <div class="method-box">
            <div class="sadia-method-img">
              <i class="fa fa-paper-plane-o"></i>
            </div>
            <div class="sadia-method-content">
              <h4>Free Shipping</h4>
              <p>Free shipping on all US order</p>
            </div>
          </div>
        </div>
        <!-- Method Box End -->

        <!-- Method Box Start -->
        <div class="col-md-3 col-lg-3">
          <div class="method-box">
            <div class="sadia-method-img">
              <i class="fa fa-headphones text-center"></i>
            </div>
            <div class="sadia-method-content">
              <h4>Have question?</h4>
              <p>Contact us 24 hours a day</p>
            </div>
          </div>
        </div>
        <!-- Method Box End -->

        <!-- Method Box Start -->
        <div class="col-md-3 col-lg-3">
          <div class="method-box">
            <div class="sadia-method-img">
              <i class="fa fa-credit-card text-center"></i>
            </div>
            <div class="sadia-method-content">
              <h4>Money guarantee</h4>
              <p>30 Days return guarantee</p>
            </div>
          </div>
        </div>
        <!-- Method Box End -->

        <!-- Method Box Start -->
        <div class="col-md-3 col-lg-3">
          <div class="method-box">
            <div class="sadia-method-img">
              <i class="fa fa-dollar text-center"></i>
            </div>
            <div class="sadia-method-content">
              <h4>Payment Secure</h4>
              <p>We ensure secure payment</p>
            </div>
          </div>
        </div>
        <!-- Method Box End -->

      </div>
    </div>
  </section>
  <!-- Policy area End -->
</template>

<script>
export default {
  name: "OurFeatures"
}
</script>

<style scoped>

</style>