<template>
  <div class="main-content-wrapper">
    <!-- about area Start  -->
    <div class="ptb--80 about-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="text-block text-center">
              <h2>About Us</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta quia maxime velit explicabo voluptas modi architecto repellendus vero delectus, facere iusto totam animi? Facilis ducimus aut assumenda reiciendis doloremque explicabo quasi soluta perspiciatis </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta quia maxime velit explicabo voluptas modi architecto repellendus vero delectus, facere iusto totam animi? Facilis ducimus aut assumenda reiciendis doloremque explicabo quasi soluta perspiciatis </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta quia maxime velit explicabo voluptas modi architecto repellendus vero delectus, facere iusto totam animi? Facilis ducimus aut assumenda reiciendis doloremque explicabo quasi soluta perspiciatis</p>
              <a href="shop.html" class="btn slider-btn mt--20 text-center">Shop Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- about area End  -->

    <!-- Team area Start -->
    <section class="team-area pb--80 text-center">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title text-center mb-50">
              <h2>Our Team <i class="fa fa-shopping-cart"></i></h2>
            </div>
          </div>
        </div>
        <div class="row pt--40">
          <div class="col-lg-3 col-sm-6 mb-sm--30 mb-md--30">
            <div class="product-grid5">
              <div class="product-image5">
                <a href="#">
                  <img class="pic-1" alt="pic1" src="assets/img/team/1.jpg">
                </a>
                <ul class="social">
                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                </ul>
                <a href="#" class="select-options">Manager</a>
              </div>
              <div class="product-content">
                <h3 class="title"><a href="#">John Doe</a></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-sm--30 mb-md--30">
            <div class="product-grid5">
              <div class="product-image5">
                <a href="#">
                  <img class="pic-1" alt="pic1" src="assets/img/team/2.jpg">
                </a>
                <ul class="social">
                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                </ul>
                <a href="#" class="select-options">CTO</a>
              </div>
              <div class="product-content">
                <h3 class="title"><a href="#">Jane Smith</a></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-xsm--30">
            <div class="product-grid5">
              <div class="product-image5">
                <a href="#">
                  <img class="pic-1" alt="pic1" src="assets/img/team/3.jpg">
                </a>
                <ul class="social">
                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                </ul>
                <a href="#" class="select-options">COO</a>
              </div>
              <div class="product-content">
                <h3 class="title"><a href="#">Alex Smith</a></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="product-grid5">
              <div class="product-image5">
                <a href="#">
                  <img class="pic-1" alt="pic1" src="assets/img/team/4.jpg">
                </a>
                <ul class="social">
                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                </ul>
                <a href="#" class="select-options">Manager</a>
              </div>
              <div class="product-content">
                <h3 class="title"><a href="#">Rohit Sharma</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Team area End -->
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>

</style>