export default {
    home: 'سەرەکی',
    shop: 'شۆپ',
    contact_us: 'پەیوەندیمان پێوەبکە',
    about_us: 'دەربارەی ئێمە',
    all_rights_reserved: 'هەموو مافەکان پارێزراون',
    language: 'زمان',
    please_select_country: 'تکایە وڵات دیاریبکە',
    countries: 'وڵاتەکان',
    select_country: 'وڵات دیاریبکە',
    featured_collections: 'کۆکراوە تایبەتەکان',
    shop_now: 'ئێستا بکڕە',
    colors: 'ڕەنگەکان',
    sale: 'فرۆشتن',
    sizes: 'قەبارەکان',
    tags: 'تاگەکان',
    share: 'هاوبەشکردن',
    order_it: 'داوای بکە',
    sizes_cm: 'قەبارەکان (سم)',
    size: 'قەبارە',
    chest: 'سنگ',
    waist: 'کەمەر',
    hips: 'سمنت',
    thigh: 'ڕان',
    length: 'درێژی',
    second_sizes_cm: 'قەبارەی خوارەوە (سم)',
    third_sizes_cm: 'قەبارەی سێیەم (سم)',
    take_3_pay_2: '٣ ببە پارەی ٢ بدە',
    trending_products: 'بەرهەمە ئاراستەکراوەکان',
    product_details: 'وردەکاریەکانی بەرهەم',
    unauthorized: 'بێ ڕێپێدراو',
    oops_this_page_is_not_available: 'ببورە! ئەم پەڕەیە بەردەست نیە',
    sorry_but_the_page_you_are_looking_for_is_not_found_please_make_sure_you_have_typed_the_current_url_correctly: 'ببوورە بەڵام ئەو پەڕەیەی بەدوایدا دەگەڕێیت نەدۆزرایەوە. تکایە دڵنیابە لەوەی کە بەستەری ئێستات بە دروستی تایپ کردووە.',
    back_to_home: 'گەڕانەوە بۆ سەرەتا',
    internal_server_error: 'هەڵەی هەیە لە سێرڤەری',
    related_products: 'بەرهەمە پەیوەندیدارەکان',
    apply_filters: 'فلتەرەکان دابنێ',
    reset_filters: 'دووبارە ڕێکخستنەوەی فلتەرەکان',
    filter_by_categories: 'فلتەر بەپێی هاوپۆلەکان',
    filter_by_brand: 'فلتەرکردن بە گوێرەی براند',
    filter_by_price: 'فلتەر بەپێی نرخ',
    no_results_found: 'هیچ ئەنجامێك نەدۆزرایەوە',
    sort_by: 'ڕێکخستن بە',
    price_high_to_low: 'نرخی بەرز بۆ بچووک',
    price_low_to_high: 'نرخی بچووک بۆ بەرز',
    newest_first: 'نوێترینی بەکارهێنان',
    oldest_first: 'کۆنترینی بەکارهێنان',
    name_a_to_z: 'ناو لە (ا بۆ ی)',
    name_z_to_a: 'ناو لە (ی بۆ ا)',
    position: 'ڕێکخستن',
    search: "گەڕان:",
    type_something: "شتێک بنووسە...",
    min_price: 'کەمترین نرخ',
    max_price: 'زۆرترین نرخ',
}