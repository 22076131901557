<template>
  <!-- footer start -->
  <footer>
    <div class="container">
      <div class="footer-bottom text-center">
        <div class="row">
          <div class="col-sm-12">
            <!-- Copyright Text Start -->
            <p class="copyright-text">
              © <a href="#">2022</a>. {{ $t('all_rights_reserved') }}
            </p>
            <!-- Copyright Text End -->
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- footer end -->
</template>

<script>
export default {
  name: "Footer"
}
</script>