import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import { i18n } from '@/plugins/i18n';
import lodash from 'lodash';
import moment from 'moment';
import methods from "./mixins";

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Paginate from 'vuejs-paginate';
Vue.component('paginate', Paginate);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import "vue-select/dist/vue-select.css";
Vue.component('v-hopper', Hooper)
Vue.component('v-slide', Slide)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.mixin({
    methods: methods
});

const plugin = {
    install() {
        Vue.moment = moment
        Vue.prototype.moment = moment
        Vue.helpers = methods
        Vue.prototype.$helpers = methods
        Vue.prototype._ = lodash
        Vue.prototype.trans = Vue.prototype.$t
    }
}

Vue.use(plugin)

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');