<template>
  <!-- Main content wrapper Start -->
  <div class="main-content-wrapper">
    <div class="shop-area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 order-lg-1 order-2">
            <!-- Sidebar Start -->
            <aside class="sidebar shop-sidebar">
              <div class="search-filter">
                <div class="filter-box mb--30">
                  <div class="filter-title">
                    <a
                        href="#"
                        class="h4 btn btn--small"
                        @click.prevent="pagination(1)"
                    >
                      {{ $t('apply_filters') }}
                    </a>
                    <div
                        v-if="
                        this.searchInputText !== null ||
                        this.serverParams.minPrice !== null ||
                        this.serverParams.maxPrice !== null ||
                        this.selectedCategories.length > 0 ||
                        this.selectedBrands.length > 0
                      "
                    >
                      <br/>
                      <a href="#" class="h4" @click.prevent="resetFilters">
                        {{ $t('reset_filters') }}
                      </a>
                    </div>
                  </div>
                </div>

                <!-- Category Search filter Start -->
                <div class="filter-box mb--30">
                  <div class="filter-title">
                    <h2>{{ $t('filter_by_categories') }}</h2>
                  </div>

                  <ul v-if="firstTimeLoading" class="search-filter-list">
                    <li class="custom-checkbox">
                      <skeleton-loader/>
                    </li>
                    <li class="custom-checkbox">
                      <skeleton-loader/>
                    </li>
                    <li class="custom-checkbox">
                      <skeleton-loader/>
                    </li>
                    <li class="custom-checkbox">
                      <skeleton-loader/>
                    </li>
                  </ul>
                  <ul v-else class="search-filter-list">
                    <li
                        class="custom-checkbox"
                        v-for="category in categories"
                        :key="category.id"
                    >
                      <input
                          type="checkbox"
                          :name="category.name"
                          :id="'category-' + category.id"
                          class="sadia-checkbox"
                          @change="categoryChanged(category.id)"
                      />
                      <label
                          :for="'category-' + category.id"
                          class="sadia-checkbox-label"
                      >{{ category.name }}</label
                      >
                    </li>
                  </ul>
                </div>
                <!-- Category Search filter End -->

                <!-- Brand Search filter Start -->
                <!--                <div class="filter-box mb&#45;&#45;30">-->
                <!--                  <div class="filter-title">-->
                <!--                    <h2>{{ $t('filter_by_brand') }}</h2>-->
                <!--                  </div>-->

                <!--                  <ul v-if="firstTimeLoading" class="search-filter-list">-->
                <!--                    <li class="custom-checkbox"><skeleton-loader /></li>-->
                <!--                    <li class="custom-checkbox"><skeleton-loader /></li>-->
                <!--                    <li class="custom-checkbox"><skeleton-loader /></li>-->
                <!--                    <li class="custom-checkbox"><skeleton-loader /></li>-->
                <!--                  </ul>-->
                <!--                  <ul v-else class="search-filter-list">-->
                <!--                    <li-->
                <!--                      class="custom-checkbox"-->
                <!--                      v-for="brand in brands"-->
                <!--                      :key="brand.id"-->
                <!--                    >-->
                <!--                      <input-->
                <!--                        type="checkbox"-->
                <!--                        :name="brand.name"-->
                <!--                        :id="'brand-' + brand.id"-->
                <!--                        class="sadia-checkbox"-->
                <!--                        @change="brandChanged(brand.id)"-->
                <!--                      />-->
                <!--                      <label-->
                <!--                        :for="'brand-' + brand.id"-->
                <!--                        class="sadia-checkbox-label"-->
                <!--                        >{{ brand.name }}</label-->
                <!--                      >-->
                <!--                    </li>-->
                <!--                  </ul>-->
                <!--                </div>-->
                <!-- Brand Search filter End -->

                <!-- Color Search filter Start -->
                <!-- <div class="filter-box mb--30" style="width: 70%">
                  <div class="filter-title">
                    <h2>Filter by color</h2>
                  </div>

                  <ul v-if="firstTimeLoading" class="search-filter-list">
                    <li class="custom-checkbox"><skeleton-loader /></li>
                    <li class="custom-checkbox"><skeleton-loader /></li>
                    <li class="custom-checkbox"><skeleton-loader /></li>
                    <li class="custom-checkbox"><skeleton-loader /></li>
                  </ul>
                  <div class="row">
                    <div
                      class="col mb--10"
                      v-for="color in colors"
                      :key="color.id"
                    >
                      <div
                        style="
                          height: 30px;
                          width: 30px;
                          border-radius: 50%;
                          cursor: pointer;
                        "
                        :style="{ 'background-color': color.hexCode }"
                        @click="colorChanged(color.id)"
                      ></div>
                    </div>
                  </div>
                </div> -->
                <!-- Color Search filter End -->

                <!-- Price Search filter Start -->
                <div class="filter-box mb--30">
                  <div class="filter-title">
                    <h2>{{ $t('filter_by_price') }}</h2>
                  </div>
                  <ul class="search-filter-list">
                    <li class="custom-radio">
                      <input
                          type="radio"
                          name="price"
                          id="lessThan10"
                          class="sadia-radio-input"
                          @change="priceFilter(0)"
                      />
                      <label for="lessThan10" class="sadia-radio-label"
                      ><span></span> &lt; &dollar;10.00</label
                      >
                    </li>
                    <li class="custom-radio">
                      <input
                          type="radio"
                          name="price"
                          id="between10And15"
                          class="sadia-radio-input"
                          @change="priceFilter(1)"
                      />
                      <label for="between10And15" class="sadia-radio-label"
                      ><span></span> &dollar;10.00 - &dollar;15.00</label
                      >
                    </li>
                    <li class="custom-radio">
                      <input
                          type="radio"
                          name="price"
                          id="between16And25"
                          class="sadia-radio-input"
                          @change="priceFilter(2)"
                      />
                      <label for="between16And25" class="sadia-radio-label"
                      ><span></span> &dollar;16.00 - &dollar;25.00</label
                      >
                    </li>
                    <li class="custom-radio">
                      <input
                          type="radio"
                          name="price"
                          id="between26And35"
                          class="sadia-radio-input"
                          @change="priceFilter(3)"
                      />
                      <label for="between26And35" class="sadia-radio-label"
                      ><span></span> &dollar;26.00 - &dollar;35.00</label
                      >
                    </li>
                    <li class="custom-radio">
                      <input
                          type="radio"
                          name="price"
                          id="between36And50"
                          class="sadia-radio-input"
                          @change="priceFilter(4)"
                      />
                      <label for="between36And50" class="sadia-radio-label"
                      ><span></span> &dollar;36.00 - &dollar;50.00</label
                      >
                    </li>
                    <li class="custom-radio">
                      <input
                          type="radio"
                          name="price"
                          id="moreThan10"
                          class="sadia-radio-input"
                          @change="priceFilter(5)"
                      />
                      <label for="moreThan10" class="sadia-radio-label"
                      ><span></span> &gt; &dollar;50.00</label
                      >
                    </li>
                    <li>
                      <div class="form-row">
                        <div class="col">
                          <input
                              type="text"
                              name="minPrice"
                              v-model="serverParams.minPrice"
                              class="form-control form-control-lg"
                              :placeholder="$t('min_price')"
                          />
                        </div>
                        <div class="col">
                          <input
                              type="text"
                              name="maxPrice"
                              v-model="serverParams.maxPrice"
                              class="form-control form-control-lg"
                              :placeholder="$t('max_price')"
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="filter-title">
                    <div
                        v-if="
                        this.searchInputText !== null ||
                        this.serverParams.minPrice !== null ||
                        this.serverParams.maxPrice !== null ||
                        this.selectedCategories.length > 0 ||
                        this.selectedBrands.length > 0
                      "
                    >
                      <a href="#" class="h4" @click.prevent="resetFilters">
                        {{ $t('reset_filters') }}
                      </a>
                    </div>
                  </div>
                  <br/>
                  <a
                      href="#"
                      class="h4 btn btn--small"
                      @click.prevent="pagination(1)"
                  >
                    {{ $t('apply_filters') }}
                  </a>
                </div>
                <!-- Price Search filter End -->
              </div>
            </aside>
            <!-- Sidebar End -->
          </div>
          <div class="col-lg-9 order-lg-2 order-1">
            <div
                class="
                shop-toolbar
                d-flex
                flex-md-row flex-column
                justify-content-between
                align-items-md-center
              "
            >
              <div class="sadia-short">
                <label>{{ $t('sort_by') }}</label>
                <select
                    id="sortBySelect"
                    class="short-select form-control form-control-lg"
                    style="width: 200px"
                    @change="sortProducts"
                >
                  <option value="1">{{ $t('position') }}</option>
                  <option value="2">{{ $t('name_a_to_z') }}</option>
                  <option value="3">{{ $t('name_z_to_a') }}</option>
                  <option value="4">{{ $t('price_low_to_high') }}</option>
                  <option value="5">{{ $t('price_high_to_low') }}</option>
                </select>
              </div>
              <div class="sadia-short">
                <label>{{ $t('search') }}</label>
                <input
                    type="text"
                    class="short-select form-control form-control-lg"
                    style="width: 200px"
                    v-model="serverParams.search"
                    @change="pagination(1)"
                />
              </div>
            </div>
            <!-- Shop Layout Start -->
            <div class="main-shop-wrapper">
              <div class="tab-content">
                <div class="tab-pane show active" id="grid">
                  <div class="product-grid-view">
                    <div v-if="isLoading" class="row">
                      <div
                          v-for="index in 9"
                          :key="index"
                          class="col-xl-4 col-md-6"
                      >
                        <OneProductGridLoader/>
                      </div>
                    </div>
                    <div v-else class="row">
                      <div
                          v-if="total === 0 && !isLoading"
                          class="col-sm-12"
                          style="
                          font-size: 25px;
                          margin: 20px;
                          text-align: center;
                        "
                      >
                        {{ $t('no_results_found') }}
                      </div>
                      <div
                          v-else
                          v-for="product in products"
                          :key="product.id"
                          class="col-xl-4 col-md-6"
                      >
                        <OneProductGrid
                            :product="product"
                            @openModal="(id) => (productModalId = id)"
                        />
                      </div>
                      <ProductModal :id="productModalId"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-md--30">
                <div class="col-12 text-center">
                  <paginate
                      class="pagination"
                      :page-count="total"
                      :click-handler="pagination"
                      prev-text='<i class="fa fa-angle-left"></i>'
                      next-text='<i class="fa fa-angle-right"></i>'
                  />
                </div>
              </div>
            </div>
            <!-- Shop Layout End -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Main content wrapper End -->
</template>

<script>
import OneProductGrid from "@/components/OneProductGrid";
import ProductModal from "@/components/ProductModal";
import $ from "jquery";
import SkeletonLoader from "@/components/SkeletonLoader";
import {notify} from "@/plugins/constants";
import OneProductGridLoader from "@/components/OneProductGridLoader";

export default {
  name: "Shop",
  components: {
    OneProductGridLoader,
    SkeletonLoader,
    ProductModal,
    OneProductGrid,
  },
  props: ["searchInputText"],
  data() {
    return {
      isLoading: false,
      firstTimeLoading: false,
      brands: [],
      selectedBrands: [],
      colors: [],
      selectedColor: null,
      categories: [],
      selectedCategories: [],
      sortBy: [],
      products: [],
      productModalId: null,
      total: 0,
      paramIsEmpty: true,
      serverParams: {
        start: 0,
        end: 9,
        search: null,
        colorId: null,
        collectionId: null,
        brands: [],
        categories: [],
        minPrice: null,
        maxPrice: null,
        sort: "id",
        order: "DESC",
      },
    };
  },
  watch: {
    searchInputText: function (val) {
      this.serverParams.search = val;
      this.pagination();
    },
  },
  mounted() {
    this.getCategories();
    this.getBrands();
    this.getColors();
    if (this.$route.query.collection) {
      this.serverParams.collectionId = this.$route.query.collection;
    }
    if (this.$route.query.type) {
      this.serverParams.productTypeId = this.$route.query.type;
    }
    if (this.$route.query.category) {
      this.serverParams.categories = [this.$route.query.category];
    }
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.isLoading = true;
      this.$store
          .dispatch("products/GET_ALL_PRODUCT", this.serverParams)
          .then((response) => {
            this.products = response.data;
            this.total = Math.ceil(
                parseFloat(response.headers["x-total-count"]) / 10
            );
          })
          .catch((error) => {
            notify(error.response.data.message, "danger");
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    pagination(pageNumber = 1) {
      this.serverParams.start = 9 * (pageNumber - 1);
      this.serverParams.end = 9 * pageNumber;
      this.serverParams.brands = this.selectedBrands;
      this.serverParams.categories = this.selectedCategories;
      this.getProducts();
    },
    priceFilter(price) {
      if (price === 0) {
        this.serverParams.maxPrice = 10;
      } else if (price === 1) {
        this.serverParams.minPrice = 10;
        this.serverParams.maxPrice = 15;
      } else if (price === 2) {
        this.serverParams.minPrice = 16;
        this.serverParams.maxPrice = 25;
      } else if (price === 3) {
        this.serverParams.minPrice = 26;
        this.serverParams.maxPrice = 35;
      } else if (price === 4) {
        this.serverParams.minPrice = 36;
        this.serverParams.maxPrice = 50;
      } else if (price === 5) {
        this.serverParams.minPrice = 50;
      }
    },
    sortProducts(sortBy) {
      if (parseInt(sortBy.target.value) === 1) {
        this.serverParams.sort = "id";
        this.serverParams.order = "DESC";
      } else if (parseInt(sortBy.target.value) === 2) {
        this.serverParams.sort = "name";
        this.serverParams.order = "ASC";
      } else if (parseInt(sortBy.target.value) === 3) {
        this.serverParams.sort = "name";
        this.serverParams.order = "DESC";
      } else if (parseInt(sortBy.target.value) === 4) {
        this.serverParams.sort = "price";
        this.serverParams.order = "ASC";
      } else if (parseInt(sortBy.target.value) === 5) {
        this.serverParams.sort = "price";
        this.serverParams.order = "DESC";
      }
      this.pagination(1);
    },
    getCategories() {
      this.firstTimeLoading = true;
      this.$store
          .dispatch("categories/GET_ALL_CATEGORIES")
          .then((response) => {
            this.categories = response.data;
          })
          .catch((error) => {
            notify(error.response.data.message, "danger");
          })
          .finally(() => {
            this.firstTimeLoading = false;
          });
    },
    categoryChanged(id) {
      let found = this.selectedCategories.findIndex((category) => {
        return category === id;
      });
      if (found >= 0) {
        this.selectedCategories.splice(found, 1);
      } else {
        this.selectedCategories.push(id);
      }
    },
    getBrands() {
      this.firstTimeLoading = true;
      this.$store
          .dispatch("brands/GET_ALL_BRANDS")
          .then((response) => {
            this.brands = response.data;
          })
          .catch((error) => {
            notify(error.response.data.message, "danger");
          })
          .finally(() => {
            this.firstTimeLoading = false;
          });
    },
    brandChanged(id) {
      let found = this.selectedBrands.findIndex((brand) => {
        return brand === id;
      });
      if (found >= 0) {
        this.selectedBrands.splice(found, 1);
      } else {
        this.selectedBrands.push(id);
      }
    },
    getColors() {
      this.firstTimeLoading = true;
      this.$store
          .dispatch("colors/GET_ALL_COLORS")
          .then((response) => {
            this.colors = response.data;
          })
          .catch((error) => {
            notify(error.response.data.message, "danger");
          })
          .finally(() => {
            this.firstTimeLoading = false;
          });
    },
    colorChanged(id) {
      this.serverParams.colorId = id;
      this.pagination(1);
    },
    resetFilters() {
      this.serverParams.sort = "id";
      this.serverParams.order = "DESC";
      this.serverParams.minPrice = null;
      this.serverParams.maxPrice = null;
      this.serverParams.search = null;
      this.serverParams.collectionId = null;
      this.serverParams.colorId = null;
      this.serverParams.brands = [];
      this.serverParams.categories = [];
      if (this.$route.query.category || this.$route.query.collection) {
        this.$router.push({name: "shop"});
      }
      this.selectedBrands = [];
      this.selectedCategories = [];
      $(".sadia-checkbox, .sadia-radio-input").prop("checked", false);
      $("#sortBySelect").val($("#sortBySelect option:first").val());
      this.pagination();
    },
  },
};
</script>